import { filter, map } from 'rxjs/operators';
import { CdkDrag, CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, SimpleChanges, Output, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EventEmitter } from 'stream';
import { FormComponent } from '../../../models/form-component.class';

@Component({
    selector: 'ngx-dual-list',
    templateUrl: './dual-list.component.html',
    styleUrls: ['./dual-list.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DualListComponent),
        multi: true,
      },
    ],
})

export class DualListComponent implements OnInit, ControlValueAccessor {

  available;
  selected;
  @Input() formControl: FormControl;
  keyName: string;

  ngOnInit(): void {
    //FIXME: take in input
    this.keyName = 'id';
    if(!this.available) {
      this.available = [];
    }
    if(!this.selected) {
      this.selected = [];
    }
  }

  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer != event.container) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.formControl.setValue({'selected': this.selected, 'available': this.available}, 
        {onlySelf: false, emitEvent: false});
    }
  }

  predicate(item: CdkDrag<number>) {
    return true;
  }

  writeValue(value: any): void {
    if(value) {
      this.selected = value.selected;
      this.available = value.available;
    }
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}