import {Form, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { parseDate } from '../utils/common-utils';
import {FormComponentType} from './form-component-type.class';
import {FormCompositeComponent} from './form-composite-component.class';

export class FormComponent {
  key: string;
  // id: string;
  parentKey: string;
  dateRange: {
    minDate?: Date,
    maxDate?: Date,
  };
  private _metadata: any;
  private _filteredOptions;

  formControl: any;
  showPlaceholder: boolean = true;

  constructor() {
  }

  get metadata(): any {
    return this._metadata;
  }

  set metadata(value: any) {
    this._metadata = value;
    if (this.type === FormComponentType.DATE && this._metadata['dateRange']) {
      this.dateRange = {};
      if (this._metadata['dateRange'].max === 'now') {
        this.dateRange.maxDate = new Date();
      } else if (this._metadata['dateRange'].max) {
        this.dateRange.maxDate = parseDate(this._metadata['dateRange'].max);
      }
      if (this._metadata['dateRange'].min === 'now') {
        this.dateRange.minDate = new Date();
      } else if (this._metadata['dateRange'].min) {
        this.dateRange.minDate = parseDate(this._metadata['dateRange'].min);
      }
    }
  }

  get type(): string {
    return this._metadata['type'];
  }
  get position(): number {
    return this._metadata['position'] ? this._metadata['position'] : 0;
  }
  get rendered(): boolean {
    return (this._metadata['rendered'] != undefined && this._metadata['rendered'] != null) ? this._metadata['rendered'] : true;
  }
  get readonly(): boolean {
    return this._metadata['readonly'];
  }
  get required(): boolean {
    return this._metadata['required'];
  }

  get filterable(): boolean {
    return this._metadata['filterable'];
  }

  get label(): any {
    return this._metadata['label'];
  }

  get placeholder(): any {
    return this._metadata['placeholder'];
  }

  get modelKey(): any {
    return this._metadata['modelKey'];
  }

  get values(): any {
    return this._metadata['values'];
  }

  get filteredOptions(): any {
    return this._filteredOptions;
  }

  set filteredOptions(values: any) {
    this._filteredOptions = values;
  }

  get pattern(): any {
    return this._metadata['pattern'];
  }

  get fullRow(): boolean {
    return this._metadata['fullRow'];
  }

  get rows(): string {
    return this._metadata['rows'];
  }

  get filterKeys(): string[] {
    return this._metadata['filterKeys'];
  }

  get filters(): string {
    return this._metadata['filters'];
  }

  get labelKeys(): string[] {
    return this._metadata['labelKeys'];
  }

  get flagChecked(): boolean {
    return this._metadata['flagChecked'] ? this._metadata['flagChecked'] : false;
  }

  get labelRendered(): boolean {
    if(this._metadata['labelRendered'] != undefined && this._metadata['labelRendered'] != null) {
      return this._metadata['labelRendered'];
    } else {
      return true;
    }
  }

  get id(): string {
    return this.key;
  }

  getLocalizedLabel(locale: string): string {
    return this.label[locale] ? this.label[locale] : this.label['en_US'];
  }

  getLocalizedPlaceholder(locale: string): string {
    if(this.placeholder) {
      return this.placeholder[locale] ? this.placeholder[locale] : this.placeholder['en_US'];
    }
    return this.label[locale] ? this.label[locale] : this.label['en_US'];
  }

  getTranslateValues(locale: string): any {
    return {
      value: this.label[locale] ? this.label[locale] : this.label['en_US'],
    };
  }

  get domain() {
    if(Array.isArray(this.filteredOptions)) {
      return this.filteredOptions;
    }
    return this.values;
  }

  getLocalizedValues(locale: string): {
    code: string,
    label: string,
  }[] {
    let options;
    if (this._filteredOptions) {
      options = this._filteredOptions;
    } else {
      options = this.values;
    }
    return options.map(value => {
      return {
        code: value.code,
        label: value.label[locale],
      };
    });
  }

  getLocalizedValue(locale: string, code: string) : string {
    if(!this.values || this.values.length == 0) {
      return code;
    }
    let values = this.getLocalizedValues(locale);
    if(values && values.length > 0) {
      return values.find(v=> v.code === code).label;
    }
    return code;
  }

  public isDetailsGroup() {
    return this.modelKey.indexOf('details.') === 0;
  }

  initFormControl(formGroup: FormGroup, detailFormGroup?: FormGroup) {
    this.formControl = new FormControl();
    const validators: ValidatorFn[] = [];
    if (this.type === FormComponentType.COMPOSITE)
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.type === FormComponentType.EMAIL) {
      validators.push(Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$'));
      validators.push(Validators.maxLength(250));
    }
    if (this.type === FormComponentType.PHONE) {
      validators.push(
        Validators.pattern(/^(\+)?([0-9]{1,4})?[0-9 ]+$/i),
        Validators.maxLength(20));
    }
    if (this.type === FormComponentType.PASSWORD) {
      validators.push(Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/));
    }
    if (this.pattern) {
      validators.push(Validators.pattern(new RegExp(this.pattern)));
    }
    if (this.type === FormComponentType.DATE && this._metadata['dateRange']) {

    }
    if (this.type === FormComponentType.PRICE) {
      validators.push(Validators.pattern(/^[0-9]+([,.][0-9]{1,2})?$/i));
    }
    if (this.type === FormComponentType.DURATION) {
      this.formControl = new FormGroup({});
      this.formControl.addControl('date', new FormControl());
      this.formControl.addControl('from', new FormControl());
      this.formControl.addControl('to', new FormControl());
    }
    if (this.required && this.type === FormComponentType.CONTACT_SELECT) {
      validators.push(FormComponent.contactSelectValidator);
    }

    if (this.readonly) {
      this.formControl.disable();
    }
    this.formControl.addValidators(validators);
    formGroup.addControl(this.modelKey, this.formControl);
  }

  static sort = (a, b) => (a.position ? a.position : 0) - (b.position ? b.position : 0);

  // listener functions
  listnerFunction = (event) => {};

  private static contactSelectValidator(control: FormControl): { [key: string]: any } | null {
    if (!control.value) {
      return {
        required: true,
      };
    }
    if (!control.value.groupId) {
      return {
          pattern: true,
      };
    }
    return null;
  }
}
