import { CdkDrag, CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { FormCompositeComponent } from '../../../models/form-composite-component.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ngx-field-flag',
    templateUrl: './field-flag.component.html',
    styleUrls: ['./field-flag.component.scss']
})

export class FieldFlagComponent implements OnInit, OnChanges {

  @Input() formGroup: FormGroup = new FormGroup([]);
  @Input() fieldInput: FormCompositeComponent;
  field: FormCompositeComponent = new FormCompositeComponent();
  fields: FormCompositeComponent[] = [];

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.fieldInput) {
      let field = new FormCompositeComponent();
      field = {...this.fieldInput} as FormCompositeComponent;
      field.metadata = {...this.fieldInput.metadata};
      field.metadata.type = field.metadata.subtype;
      // field.metadata.readonly = !field.metadata.flagChecked;
      // this.formGroup.removeControl(field.metadata.modelKey);
      // this.formGroup.addControl(this.field.metadata.modelKey+ 'FLAG', new FormControl());
      Object.assign(this.field, field);
      this.field.initFormControl(this.formGroup);
      if(this.field.metadata.flagChecked) {
        this.getFormControl().enable();
      } else {
        this.getFormControl().disable();
      }
      this.fields.push(this.field);
    }
  }

  getFormControl() : AbstractControl {
    return this.formGroup.controls[this.field.metadata.modelKey];
  }

  selectFlag(checked) {
    if(checked) {
      this.getFormControl().enable();
    } else {
      this.getFormControl().setValue(this.field.metadata.defaultValue);
      this.getFormControl().disable();
    }
  }

  get locale() {
    return this.translateService.currentLang;
  }
}