import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private BASE_URL: string = '/api/';

  constructor(
  ) { }

  getApiUrl() {
    const baseUrl = new URL(window.location.href);
    return this.BASE_URL;
  }

}
