<div class="logo-box">
  <img src="assets/images/logo/logo_primario_white_payoff.png" style="width: 100%">
</div>

<nb-card>
  <nb-card-body>
    <nb-auth-block>
      <h1 id="title" class="title">{{'RESET_PASSWORD.TITLE'|translate}}</h1>

      <nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>{{'ERROR'|translate}}!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let error of errors" class="alert-message">{{ 'RESET_PASSWORD.' + error |translate}}</li>
        </ul>
      </nb-alert>

      <nb-alert *ngIf="messages?.length" outline="success" role="alert">
        <p class="alert-title"><b>{{'SUCCESS'|translate}}!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let message of messages" class="alert-message">{{ message |translate }}</li>
        </ul>
        <p class="text-center mb-0">
          <button nbButton
                  status="primary"
                  size="large"
                  (click)="goToLogin()">
            {{ 'RESET_PASSWORD.GO_TO_LOGIN' |translate }}
          </button>
        </p>
      </nb-alert>

      <form (ngSubmit)="resetPassword()" #form="ngForm" aria-labelledby="title">

        <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">{{'RESET_PASSWORD.PASSWORD'|translate}}</label>
          </span>
          <input nbInput
                 fullWidth
                 [(ngModel)]="user.newPassword"
                 #newPassword="ngModel"
                 name="newPassword"
                 type="password"
                 id="input-password"
                 placeholder="Password"
                 fieldSize="large"
                 [disabled]="submitted"
                 [status]="newPassword.dirty ? (newPassword.invalid  ? 'danger' : 'success') : 'basic'"
                 required="true"
                 [minlength]="8"
                 [maxlength]="20"
                 pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_]).{8,}"
                 [attr.aria-invalid]="newPassword.invalid && newPassword.touched ? true : null">
          <ng-container *ngIf="newPassword.invalid && newPassword.touched ">
            <p class="caption status-danger" *ngIf="newPassword.errors?.required">
              {{'RESET_PASSWORD.PASSWORD_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="newPassword.errors?.pattern">
              {{'FORM.VALIDATION_ERRORS.INVALID_PASSWORD_FIELD'|translate: ['Password']}}
            </p>
          </ng-container>
        </div>

        <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">{{'RESET_PASSWORD.PASSWORD_CONFIRM'|translate}}</label>
          </span>
          <input nbInput
                 fullWidth
                 [(ngModel)]="user.newPassword2"
                 #newPassword2="ngModel"
                 name="newPassword2"
                 type="password"
                 id="input-newPassword2"
                 placeholder="Password"
                 fieldSize="large"
                 [disabled]="submitted"
                 [status]="newPassword2.dirty ? (newPassword2.invalid  ? 'danger' : 'success') : 'basic'"
                 required="true"
                 [minlength]="8"
                 [maxlength]="20"
                 pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_]).{8,}"
                 [attr.aria-invalid]="newPassword2.invalid && newPassword2.touched ? true : null">
          <ng-container *ngIf="newPassword2.invalid && newPassword2.touched ">
            <p class="caption status-danger" *ngIf="newPassword2.errors?.required">
              {{'RESET_PASSWORD.PASSWORD_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="newPassword2.errors?.pattern">
              {{'FORM.VALIDATION_ERRORS.INVALID_PASSWORD_FIELD'|translate: ['Password']}}
            </p>
          </ng-container>
        </div>

        <button nbButton
                fullWidth
                status="primary"
                size="large"
                [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted">
          {{'RESET_PASSWORD.UPDATE_PASSWORD'|translate}}
        </button>
      </form>
    </nb-auth-block>
  </nb-card-body>
</nb-card>
