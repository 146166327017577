<div class="logo-box">
  <img src="assets/images/logo/logo_primario_white_payoff.png" style="width: 100%">
</div>

<nb-card>
  <nb-card-body>
    <nb-auth-block>
      <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>Oh snap!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
        </ul>
      </nb-alert>

      <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
        <p class="alert-title"><b>Hooray!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
        </ul>
      </nb-alert>

      <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

        <div class="form-control-group">
          <label class="label" for="input-username">{{'LOGIN.USERNAME'|translate}}</label>
          <input nbInput
                 fullWidth
                 [(ngModel)]="user.username"
                 #username="ngModel"
                 name="username"
                 id="input-username"
                 pattern="[a-zA-Z0-9_\.]+"
                 placeholder="Username"
                 fieldSize="large"
                 autofocus
                 [status]="username.dirty ? (username.invalid  ? 'danger' : 'success') : 'basic'"
                 [required]="getConfigValue('forms.validation.username.required')"
                 [attr.aria-invalid]="username.invalid && username.touched ? true : null">
          <ng-container *ngIf="username.invalid && username.touched">
            <p class="caption status-danger" *ngIf="username.errors?.required">
              {{'LOGIN.USERNAME_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="username.errors?.pattern">
              {{'LOGIN.USERNAME_INVALID'|translate}}
            </p>
          </ng-container>
        </div>

        <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">{{'LOGIN.PASSWORD'|translate}}</label>
      <a class="forgot-password caption-2" routerLink="../lost-password">{{'LOGIN.FORGOT_PASSWORD'|translate }}</a>
    </span>
          <input nbInput
                 fullWidth
                 [(ngModel)]="user.password"
                 #password="ngModel"
                 name="password"
                 type="password"
                 id="input-password"
                 placeholder="Password"
                 fieldSize="large"
                 [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                 required="true"
                 [minlength]="8"
                 [maxlength]="20"
                 [attr.aria-invalid]="password.invalid && password.touched ? true : null">
          <ng-container *ngIf="password.invalid && password.touched ">
            <p class="caption status-danger" *ngIf="password.errors?.required">
              {{'LOGIN.PASSWORD_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="password.errors?.pattern">
              {{'LOGIN.PASSWORD_INVALID'|translate}}
            </p>
          </ng-container>
        </div>

        <div class="form-control-group" *ngIf="user.enabled2FA">
    <span class="label-with-link">
      <label class="label" for="input-password">{{'LOGIN.OTP'|translate}}</label>
    </span>
          <input nbInput
                 fullWidth
                 [(ngModel)]="user.otp"
                 #otp="ngModel"
                 name="otp"
                 type="otp"
                 id="input-otp"
                 placeholder="OTP"
                 pattern="[0-9]{6,8}"
                 fieldSize="large"
                 [status]="otp.dirty ? (otp.invalid  ? 'danger' : 'success') : 'basic'"
                 required="true"
                 [attr.aria-invalid]="otp.invalid && otp.touched ? true : null">
          <ng-container *ngIf="otp.invalid && otp.touched ">
            <p class="caption status-danger" *ngIf="otp.errors?.required">
              {{'LOGIN.OTP_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="otp.errors?.pattern">
              {{'LOGIN.OTP_INVALID'|translate}}
            </p>
          </ng-container>
        </div>

        <div class="form-control-group accept-group">
          <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">{{'LOGIN.REMEMBER_ME'|translate}}</nb-checkbox>
        </div>

        <button nbButton
                fullWidth
                status="primary"
                size="large"
                [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted">
          {{'LOGIN.LOG_IN'|translate}}
        </button>
      </form>
    </nb-auth-block>
  </nb-card-body>
</nb-card>
