import {Injectable} from '@angular/core';

export class FormComponentType {

  public static COMPOSITE = 'COMPOSITE';
  public static STRING = 'STRING';
  public static TEXTAREA = 'TEXTAREA';
  public static PASSWORD = 'PASSWORD';
  public static EMAIL = 'EMAIL';
  public static PHONE = 'PHONE';
  public static DATE = 'DATE';
  public static DATETIME = 'DATETIME';
  public static TIME = 'TIME';
  public static DURATION = 'DURATION';
  public static DOMAIN = 'DOMAIN';
  public static PRICE = 'PRICE';
  public static CONTACT_SELECT = 'CONTACT_SELECT';
  public static UPLOAD = 'UPLOAD';
  public static DUAL_LIST = 'DUAL_LIST';
  public static FIELD_FLAG = 'FIELD_FLAG';
  public static MULTIPLE_CONTACTS = 'MULTIPLE_CONTACTS';

}
