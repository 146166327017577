import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContactStatus } from './../../../../pages/contacts/models/contact-status.class';
import { FormCompositeComponent } from './../../../models/form-composite-component.class';

@Component({
  selector: 'ngx-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  
  private _status: ContactStatus;
  private _substatus: ContactStatus;

  @Input()
  set status(value: string) {
    this._status = ContactStatus.getByLabel(value);
  }

  @Input()
  set substatus(value: string) {
    this._substatus = ContactStatus.getByLabel(value);
  }

  getStatus(): ContactStatus {
    return this._status;
  }

  getSubStatus(): ContactStatus {
    return this._substatus;
  }

  isCurrent(status: ContactStatus) {
    return this._status && this._status === status;
  }

  public contactStatus = ContactStatus;

  constructor() { }

  ngOnInit(): void {
  }
}
