import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ClientService } from '../../../../pages/contacts/services/client.service';
import { FormCompositeComponent } from '../../../models/form-composite-component.class';
import { flatten } from '../../../utils/flatten.util';

@Component({
  selector: 'ngx-multiple-component-search',
  templateUrl: './multiple-contact-search.component.html',
  styleUrls: ['./multiple-contact-search.component.scss'],
})
export class MultipleContactSearchComponent implements OnInit, OnChanges {

  @Input() field: FormCompositeComponent;
  @Input() formGroup: FormGroup;
  control: FormControl = new FormControl();
  contactList: any[] = [];

  constructor(private translateService: TranslateService,
    private clientService: ClientService) {
  }
  
  get locale() {
    return this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.control.valueChanges
    .pipe(
      filter(value => value != null && typeof value[0] === 'string' && value[0].length > 20),
      take(1),
      tap(idsList => {
        idsList.forEach(id=> {
          this.clientService.getData(id).pipe(
            take(1)
          ).subscribe(contact => this.addContactToList(contact));
        }
      )})
      ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.formGroup) {
      this.formGroup.addControl(this.field.modelKey + 'List', this.control);
    }
  }

  selectionChange(contact) {
    if(contact && contact.id) {
      if(this.addContactToList(contact)) {
        this.updateControlValue();
        this.field.formControl.setValue('');
      }
    }
  }

  removeContact(contact) {
    this.contactList = this.contactList.filter(c=> c.id != contact.id);
    this.updateControlValue();
  }

  addContactToList(contact) : boolean {
    if(!this.contactList.some(c => contact.id === c.id)) {
      this.contactList.push(contact);
      return true;
    }
    return false;
  }

  updateControlValue() {
    this.control.setValue(this.contactList.map(c=>c.id));
  }

  public getDetails = (contact) => {
    if (this.field.labelKeys) {
      contact = flatten(contact);
      return this.field.labelKeys.map(label => contact[label] != null ? contact[label] : '').join(' ');
    }
  }
}
