import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  private _supportedLocales: any[] = [
    {
      code: 'it_IT',
      value: 'IT',
    }, {
      code: 'en_US',
      value: 'EN',
    }];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private translateService: TranslateService,
    private router: Router,
  ) { }

  getBrowserLocale(): string {
    return this.locale.replace('-', '_');
  }

  getCurrentLocale(): string {
    const locale = localStorage.getItem('locale');
    if (locale) {
      return locale;
    } else if (this._supportedLocales.map(loc => loc.code).indexOf(this.getBrowserLocale()) > -1) {
      return this.getBrowserLocale();
    } else {
      return 'en_US';
    }
  }

  setLocale(locale: string): void {
    localStorage.setItem('locale', locale);
    this.translateService.use(locale);
    console.log('Changed Language to ' + locale);
  }

  initLocale(): void {
    this.translateService.use(this.getCurrentLocale());
  }
  get supportedLocales(): any[] {
    return this._supportedLocales;
  }
}
