import {
  NbAuthJWTToken,
  NbAuthResult, NbAuthSimpleToken,
  NbAuthStrategy,
  NbAuthStrategyClass,
  NbPasswordAuthStrategyOptions,
} from '@nebular/auth';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BaseOutput} from '../../core/models/base-output.class';
import {User} from '../../core/models/user.class';
import {ConfigService} from '../../core';
import {Injectable} from '@angular/core';

@Injectable()
export class CrmAuthStrategy extends NbAuthStrategy {

  public static STRATEGY_NAME = 'crm-strategy';

  constructor(private config: ConfigService,
              private http: HttpClient,
  ) {
    super();
  }

  static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [CrmAuthStrategy, options];
  }

  authenticate(data?: any): Observable<NbAuthResult> {
    if (data.enabled2FA) {
      return this.checkOTP(data);
    } else {
      return this.login(data);
    }
  }

  private login(data?: any): Observable<NbAuthResult> {
    const module = 'login';
    const body = new HttpParams()
      .set('username', data.username)
      .set('password', data.password);
    return this.http.post<BaseOutput<any>>(this.config.getApiUrl() + 'auth/v1/2FA/login',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded'),
      }).pipe(
      map(response => {
        if (!response.data.enabled2FA) {
          return new NbAuthResult(
            response.success,
            response.data,
            this.getOption(`${module}.redirect.success`),
            !response.success ? response.data.detail : null,
            null,
            new NbAuthJWTToken(response.data.token, this.getOption('name')),
          );
        } else {
          return new NbAuthResult(
            false,
            response.data,
            null,
            !response.success ? response.data.detail : null,
            null,
            new NbAuthSimpleToken(response.data.sessionId, this.getOption('name')),
          );
        }
      }),
    );
  }

  checkOTP(data?: any): Observable<NbAuthResult> {
    const module = 'login';
    const body = new HttpParams()
    .set('username', data.username)
    .set('sessionId', data.sessionId)
    .set('otp', data.otp);
    return this.http.post<BaseOutput<any>>(this.config.getApiUrl() + 'auth/v1/2FA/checkOTP',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded'),
      }).pipe(
      map(response =>
        new NbAuthResult(
          response.success,
          response.data,
          this.getOption(`${module}.redirect.success`),
          !response.success ? response.data.detail : null,
          null,
          new NbAuthJWTToken(response.data.token, this.getOption('name')),
        ),
      ),
    );
  }

  logout(): Observable<NbAuthResult> {
    return this.http.post<BaseOutput<string>>(this.config.getApiUrl() + 'auth/v1/logout/', {}).pipe(
      map(response =>
        new NbAuthResult(
          response.success,
          response,
          '/auth/login',
        ),
      ),
    );
  }

  refreshToken(data?: any): Observable<NbAuthResult> {
    return this.http.get<BaseOutput<string>>(this.config.getApiUrl() + 'auth/v1/refresh/').pipe(
      map(response =>
        new NbAuthResult(
          response.success,
          response,
          null,
          null,
          null,
          new NbAuthJWTToken(response.data, this.getOption('name')),
        ),
      ),
    );
  }

  register(data?: any): Observable<NbAuthResult> {
    return undefined;
  }

  requestPassword(data?: any): Observable<NbAuthResult> {
    return this.http.post<BaseOutput<any>>(this.config.getApiUrl() + 'auth/v1/lostPassword', data).pipe(
      map(response =>
        new NbAuthResult(
          response.success,
          response,
          null,
          null,
          null,
          new NbAuthSimpleToken(null, this.getOption('name')),
        ),
      ),
    );
  }

  resetPassword(data?: any): Observable<NbAuthResult> {
    return this.http.post<BaseOutput<any>>(this.config.getApiUrl() + 'auth/v1/resetPassword', data).pipe(
      map(response =>
        new NbAuthResult(
          response.success,
          response,
          null,
          !response.success ? response.data.detail : null,
          null,
          new NbAuthSimpleToken(null, this.getOption('name')),
        ),
      ),
    );
  }

  sessionData(): Observable<BaseOutput<User>> {
    return this.http.get<BaseOutput<User>>(this.config.getApiUrl() + 'auth/v1/sessionData');
  }

}
