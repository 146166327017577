import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {BehaviorSubject, Observable, of, Subject, take} from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { ClientService } from '../../../../pages/contacts/services/client.service';
import { flatten } from '../../../utils/flatten.util';

@Component({
  selector: 'ngx-component-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent implements OnInit {

  constructor( private clientService: ClientService) {
  }

  @Input() searchLabel: string;
  @Input() name: string;
  @Input() id: string;
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() status: string;
  @Input() formControlName: string;
  @Input() disabled: boolean;
  private _filters: string[];
  private _labels: string[];
  @Input() defaultFilters: string;
  @Input() control: FormControl;
  @Output() selectionChange = new EventEmitter<any>();

  filterChanged = new Subject<any>();
  filteredOptions$: Observable<any[]>;

  @ViewChild('autoInput') input;

  ngOnInit() {
    this.filteredOptions$ = new BehaviorSubject<any[]>([]);
    this.searchLabel = 'Ricerca Clienti';
    this.control.valueChanges.pipe(
      filter(value => value != null && typeof value !== 'object' && value.length > 20),
      take(1),
      mergeMap(value => this.clientService.getData(value)))
      .subscribe(value => this.control.setValue(flatten(value)));
    if (this.control.value) {
      this.control.setValue(this.control.value);
    }

    this.filteredOptions$ = this.filterChanged.pipe(
      debounceTime(500),
      // filter(value => typeof value !== 'object'),
      mergeMap(value => {
        if (value != null && value.length >= 2 && value.indexOf(' ') === -1) {
          const keysFilter = this.filters.map(filtro => filtro + ` ilike '%${value}%'`).join(' or ');
          let jointString = '';
          if (this.defaultFilters && keysFilter) {
            jointString = ' and ';
          }
          let filtersValue = (this.defaultFilters ? this.defaultFilters : '') + jointString +
            (keysFilter ? '(' + keysFilter + ')' : '');
          return this.clientService.baseFilters.pipe(
            mergeMap(baseFilters => {
              if (baseFilters) {
                filtersValue = baseFilters + (filtersValue ? (' and ' + filtersValue) : '');
              }
              return this.clientService.readMulti(null, filtersValue, null, '10,0');
            }),
            map(data => data.map(r => flatten(r))));
        } else {
          return of([]);
        }
      }),
    );
  }

  get filters(): string[] {
    return this._filters ? this._filters : ['details.first_name', 'details.last_name', 'details.denomination', 'email'];
  }

  @Input()
  set filters(value: string[]) {
    this._filters = value;
  }

  get labels(): string[] {
    return this._labels ? this._labels : ['details.first_name', 'details.last_name', 'details.denomination', 'email'];
  }

  @Input()
  set labels(value: string[]) {
    this._labels = value;
  }

  onKeyUp($event) {
    this.filterChanged.next($event.target.value);
  }

  onSelectionChange($event) {
    if ($event) {
      console.log($event);
      if (this.selectionChange) {
        this.selectionChange.emit($event);
        console.log('on selection change');
      }
    }
  }

  public valueMapper = (key) => {
    if (typeof key === 'object' && this.labels) {
      return this.labels.filter(label=>key[label]).map(label=>key[label]).join(' ');
    } else {
      return key;
    }
  }

}
