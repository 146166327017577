import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmAuthService} from '../auth.service';
import {NB_AUTH_OPTIONS, NbAuthResult} from '@nebular/auth';
import {Router} from '@angular/router';
import {CrmAuthStrategy} from '../strategy/crm-auth-strategy.class';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class CrmResetPasswordComponent implements OnInit {
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  rememberMe = false;

  constructor(protected service: CrmAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected router: Router) {
  }

  ngOnInit(): void {
    this.service.isAuthenticated().subscribe(authenticated => {
      if (!authenticated) {
        this.router.navigate(['/auth/login']);
      }
    });
    this.strategy = CrmAuthStrategy.STRATEGY_NAME;
  }

  goToLogin(): void {
    this.router.navigate(['/auth/login']);
  }

  resetPassword(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.resetPassword(this.strategy, this.user)
      .subscribe((result: NbAuthResult) => {
        if (result.isSuccess()) {
          this.messages.push('RESET_PASSWORD.PASSWORD_RESET_SUCCESS');
        } else {
          this.errors.push(result.getErrors()[0]);
          this.submitted = false;
        }
      }, error => {
        this.submitted = false;
        this.errors.push(error);
      });

  }

}
