import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {User} from '../models/user.class';
import {Alert} from '../models/alert.class';
import {NbToastrService} from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(
    private toastService: NbToastrService,
  ) {
  }

  public addAlert(status: string, message: string, duration?: number) {
    this.toastService.show(null, message, {
      status: status,
      duration: duration ? duration : 3000,
    });
  }

}
