import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { DefaultFilter } from 'ng2-smart-table';
import {NbDialogRef} from '@nebular/theme';

@Component({
  template: `
    <nb-card>
      <nb-card-header>{{ title | translate }}</nb-card-header>
      <nb-card-body>{{ text | translate }}</nb-card-body>
      <nb-card-footer>
        <div class="row">
          <div class="col-6 text-center">
            <button nbButton (click)="submit()" status="primary">{{confirmLabel | translate}}</button>
          </div>
          <div class="col-6 text-center">
            <button nbButton (click)="cancel()">{{cancelLabel | translate}}</button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  `,
})
export class ConfirmDialogComponent {

  title: string;
  text: any;

  confirmLabel: string = 'CONFIRM_DIALOG.CONFIRM';
  cancelLabel: string = 'CONFIRM_DIALOG.CANCEL';

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) {
  }

  cancel() {
    this.ref.close(false);
  }

  submit() {
    this.ref.close(true);
  }

}
