import { CommonModule } from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { CrmAuthRoutingModule } from './auth-routing.module';
import {NbAuthJWTToken, NbAuthModule} from '@nebular/auth';
import {
  NbAlertModule,
  NbButtonModule, NbCardModule,
  NbCheckboxModule, NbIconModule,
  NbInputModule, NbLayoutModule, NbRadioModule,
} from '@nebular/theme';
import {CrmLoginComponent} from './login/login.component';
import {CrmAuthStrategy} from './strategy/crm-auth-strategy.class';
import {AuthGuard} from './auth-guard.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpTokenInterceptor} from './interceptors';
import {PermissionGuard} from './permission-guard.service';
import {CrmCoreModule} from '../core/crm-core.module';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {CrmJwtLoginComponent} from './jwt-login/jwt-login.component';
import {JWT_OPTIONS, JwtHelperService, JwtModule} from '@auth0/angular-jwt';
import { CrmLogoutComponent } from './logout/logout.component';
import {TranslateModule} from '@ngx-translate/core';
import {CrmAuthService} from './auth.service';
import { CrmLostPasswordComponent } from './lost-password/lost-password.component';
import {CrmResetPasswordComponent} from './reset-password/reset-password.component';
import {CrmAuthComponent} from './auth.component';
import { CrmUpdatePasswordComponent } from './update-password/update-password.component';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    CrmCoreModule,
    CrmAuthRoutingModule,
    NbSecurityModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        CrmAuthStrategy.setup({
          name: CrmAuthStrategy.STRATEGY_NAME,
          token: {
            class: NbAuthJWTToken,
          },
          login: {
            redirect: {
              success: '/pages/dashboard',
              failure: null,
            },
          },
        }),
      ],
      forms: {},
    }),
    TranslateModule,
    NbLayoutModule,
    NbCardModule,
    NbIconModule,
    NbRadioModule,
  ],
  declarations: [
    CrmAuthComponent,
    CrmLoginComponent,
    CrmJwtLoginComponent,
    CrmLogoutComponent,
    CrmLostPasswordComponent,
    CrmResetPasswordComponent,
    CrmUpdatePasswordComponent,
  ],
  providers: [
    CrmAuthStrategy,
    CrmAuthService,
    AuthGuard,
    PermissionGuard,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
  ],
})
export class CrmAuthModule {
  static forRoot(): ModuleWithProviders<CrmAuthModule> {
    return {
      ngModule: CrmAuthModule,
      providers: [
        HttpTokenInterceptor,
        NbSecurityModule.forRoot({
          accessControl: {
            guest: {
              view: '*',
            },
            user: {
              parent: 'guest',
              create: '*',
              edit: '*',
              remove: '*',
            },
          },
        }).providers,
        {
          provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
        },
      ],
    };
  }
}
