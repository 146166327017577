import { Injectable } from '@angular/core';
import {ConfigService} from '../../../core';
import {HttpClient} from '@angular/common/http';
import {AbstractCrudService} from '../../../core/services/abstract-crud.service';
import {SessionStorageService} from '../../../core/services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReminderService extends AbstractCrudService {

  constructor(config: ConfigService,
              http: HttpClient,
              sessionService: SessionStorageService,
  ) {
    super();
    this.config = config;
    this.http = http;
    this.sessionService = sessionService;
  }

  get baseUrl(): string {
    return 'reminder/v1/reminder/';
  }

}
