<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <div class="header-container">
        <div class="logo-container">
          <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" *ngIf="isPageUrl()">
            <nb-icon icon="menu-2-outline"></nb-icon>
          </a>
          <a class="logo" href="#" (click)="navigateHome()">
            <img src="assets/images/logo/logo_primario@2x_NoPayOff.png" style="width: 100%">
          </a>
        </div>
      </div>
    </div>
    <div class="col-6" *ngIf="isPageUrl()">
      <div class="header-container right-section">
        <nb-actions size="small">

          <nb-action class="control-item" *ngIf="showSearch">
            <nb-search type="rotate-layout"></nb-search>
          </nb-action>
          <nb-action class="control-item"
                     icon="checkmark-square-outline"
                     link="/pages/reminders"
                     [badgeDot]="reminderPresent|async"
                     badgePosition="bottom right"
                     badgeStatus="warning"
          ></nb-action>
          <nb-action class="user-action" >
            <nb-select class="lang-select" [(ngModel)]="locale" (selectedChange)="updateLocale()" status="primary">
              <nb-option *ngFor="let loc of supportedLocales" [value]="loc.code"> {{ loc.value }}</nb-option>
            </nb-select>
          </nb-action>
          <nb-action class="user-action" *ngIf="isPageUrl()" >
            <nb-user [nbContextMenu]="userMenu"
                     [onlyPicture]="userPictureOnly"
                     [name]="userName"
                     [picture]="user?.picture">
            </nb-user>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </div>
</div>



