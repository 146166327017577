import {Component, OnInit} from '@angular/core';
import {NbAuthJWTToken, NbTokenService} from '@nebular/auth';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DatePipe} from '@angular/common';
import {CrmAuthStrategy} from '../strategy/crm-auth-strategy.class';
import {LocaleService} from '../../core/services/locale.service';
import {ClientService} from '../../pages/contacts/services/client.service';

@Component({
  selector: 'ngx-crm-jwt-login',
  styleUrls: ['./jwt-login.component.scss'],
  templateUrl: './jwt-login.component.html',
})
export class CrmJwtLoginComponent implements OnInit {
  token: string;
  claims: any;
  valid: boolean;
  expires: Date;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jwtService: JwtHelperService,
    private tokenService: NbTokenService,
    private localeService: LocaleService,
    private clientService: ClientService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      this.token = queryParams['token'];
      this.valid = !this.jwtService.isTokenExpired(this.token);
      if (this.valid) {
        this.claims = this.jwtService.decodeToken(this.token);
        this.expires = new Date(this.claims.exp * 1000);

        this.tokenService.set(new NbAuthJWTToken(this.token, CrmAuthStrategy.STRATEGY_NAME))
          .subscribe(token => {
            this.setLocale();
            this.goToPage();
          });
      } else {

      }
    });
  }

  goToLogin(): void {
    this.router.navigate(['/auth/login']);
  }

  setLocale(): void {
    if (this.claims.locale) {
      switch (this.claims.locale) {
        case 'it':
          this.localeService.setLocale('it_IT');
          break;
        case 'en':
          this.localeService.setLocale('en_US');
          break;
      }
    }
  }

  goToPage() {
    switch (this.claims.action) {
      case 'client-view':
        this.clientService.getData(this.claims.clientId).subscribe( data => {
          if (data.details.type === 'PP') {
            this.router.navigate(['/views/contacts/' + this.claims.clientId + '/detail']);
          } else {
            this.router.navigate(['/views/contacts/' + this.claims.clientId + '/detail-company']);
          }
        });
        break;
      case 'calendar-view':
        this.router.navigate(['/views/meetings/calendar'],
          {
            queryParams: {
              view: this.claims.view,
              startDate: this.claims.startDate,
            },
          });
        break;
      case 'reset-password':
        this.router.navigate(['/auth/reset-password/']);
        break;
      case 'user-stats':
        this.router.navigate(['/views/dashboard/']);
        break;
      case 'shopping-cart':
        this.router.navigate(['/views/shopping-cart/']);
        break;
    }
  }

}
