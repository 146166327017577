
<div class="row">
    <div class="col-10 col-md-10">
        <label [for]="field.id">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
        <ngx-fink-fieldset [columns]="1" [formFields]="this.fields" [formGroup]="formGroup">
        </ngx-fink-fieldset>
    </div>
      <div class="col-2 col-md-2">
        <div class="fink-field-wrapper" style="text-align: center;">
            <label [for]="'flag'"> {{field.metadata.flagLabel}}</label>
            <div>
                <nb-checkbox 
                    id="flag"
                    (checkedChange)="selectFlag($event)" 
                    [checked]="field.flagChecked">
                </nb-checkbox>
            </div>
        </div>
      </div>
</div>