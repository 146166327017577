import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {NbAuthJWTToken, NbTokenService} from '@nebular/auth';
import {catchError, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CrmAuthStrategy} from '../strategy/crm-auth-strategy.class';
import {CrmAuthService} from '../auth.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private authService: CrmAuthService,
              private tokenService: NbTokenService,
              private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return this.authService.getToken().pipe(
        mergeMap( (oldToken: NbAuthJWTToken) => {
          // if the token is expiring, generate a new one
          const oldJWTToken: NbAuthJWTToken = new NbAuthJWTToken(oldToken.toString(), CrmAuthStrategy.STRATEGY_NAME);
          if (oldJWTToken.getPayload()) {
            const limitDate: Date = new Date(new Date().getTime() + 20 * 60 * 1000); // now + 5 minutes
            if (oldJWTToken.getTokenExpDate().getTime() < limitDate.getTime() &&
              !request.url.includes('auth/v1/refresh') &&
              !request.url.includes('auth/v1/login') &&
              !request.url.includes('auth/v1/2FA') &&
              !request.url.includes('auth/v1/lostPassword') &&
              !request.url.startsWith('./assets')) {
              console.log('Refresh Token!');
              return this.authService.refreshToken(CrmAuthStrategy.STRATEGY_NAME).pipe(
                mergeMap(result => {
                  return this.tokenService.set(result.getToken());
                }),
                mergeMap(token => {
                  return this.authService.getToken();
                }),
                mergeMap( token => {
                  return this.addHeader(request, next, token.toString());
                }),
                catchError(err => {
                  this.router.navigate(['/auth/login']);
                  return throwError(err);
                }),
              );
            } else {
              return this.authService.getToken().pipe(
                mergeMap( token => this.addHeader(request, next, token.toString())),
              );
            }
          } else {
            return next.handle(request);
          }
        }),
      );
  }

  private addHeader(request: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
    const isLoggedIn = token !== null;
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }
}
