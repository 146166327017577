import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'timeline-item',
  template: `
    <div class="content">
      <ng-content></ng-content>
    </div>
    <span class="timestamp">{{timestamp}}</span>
    <span class="dot-a" [ngStyle]="dotA"></span>
    <span class="line"></span>
    <nb-icon class="dot" [ngStyle]="dot" [icon]="icon" [options]="options"></nb-icon>
  `,
})
export class TimelineItemComponent implements OnInit {
  @Input() color;
  @Input() timestamp: string;
  private _icon: string;

  options = {
    'icon-height': '1em',
  };

  dot: { [klass: string]: any; };
  dotA: { [klass: string]: any; };

  constructor() {
  }

  ngOnInit(): void {
    if (this.color) {
      this.dot = {
        'background-color': this.color,
        'box-shadow': `0 0 2px 5px #fff,0 0 0 7px #808080,0 0 0 12px #fff,0 0 0 13px ${this.color}`
      };
      this.dotA = {
        'background-color': this.color
      };
    }
  }

  @Input()
  set icon(value: string) {
    this._icon = value;
  }

  get icon(): string {
    return this._icon;
  }
}

