<input #autoInput
           nbInput fullWidth
           type="text"
           [placeholder]="placeholder"
           [nbAutocomplete]="auto"
           [name]="name"
           [required]="required"
           [status]="status"
           [formControl]="control"
           (keyup)="onKeyUp($event)"
           id="autoInput"/>

    <nb-autocomplete #auto
                     (selectedChange)="onSelectionChange($event)"
                     [handleDisplayFn]="valueMapper">
      <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        {{ valueMapper(option)}}
      </nb-option>
    </nb-autocomplete>
