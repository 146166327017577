import {FormComponent} from './form-component.class';
import {FormControl, FormGroup} from '@angular/forms';
import {FormComponentType} from './form-component-type.class';

export class FormCompositeComponent extends FormComponent {
  key: string;
  // id: string;
  parentKey: string;

  formGroup: FormGroup;

  parent: FormCompositeComponent;
  private _children: FormCompositeComponent[];

  constructor() {
    super();
    this._children = [];
  }

  get children(): FormCompositeComponent[] {
    return this._children;
  }

  set children(value: FormCompositeComponent[]) {
    this._children = value;
  }

  public isDetailsGroup() {
    return this.modelKey.indexOf('details.') === 0;
  }

  initFormControl(formGroup: FormGroup) {
    if (this.type === FormComponentType.COMPOSITE) {
      this.children.forEach(child => child.initFormControl(formGroup));
    } else {
      super.initFormControl(formGroup);
    }
  }
}
