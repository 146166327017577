import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CrmAuthService} from '../auth.service';
import {getDeepFromObject, NB_AUTH_OPTIONS, NbAuthResult} from '@nebular/auth';
import {Router} from '@angular/router';
import {CrmAuthStrategy} from '../strategy/crm-auth-strategy.class';
import { UserService } from '../../pages/users/services/user.service';

@Component({
  selector: 'ngx-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss'],
})
export class CrmLostPasswordComponent implements OnInit {
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {recoveryMode: 'telegram'};
  submitted: boolean = false;
  rememberMe = false;
  recoveryOptions = [
    { value: 'telegram', label: 'Telegram' },
    { value: 'email', label: 'Email' },
  ];

  constructor(protected service: CrmAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router,
              protected userService: UserService) {
  }

  ngOnInit(): void {
    this.strategy = CrmAuthStrategy.STRATEGY_NAME;
  }

  sendResetPassword(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
      this.service.requestPassword(this.strategy, this.user)
        .subscribe((result: NbAuthResult) => {
          this.messages.push('LOST_PASSWORD.LINK_SENT_SUCCESS');
        }, error => {
          this.submitted = false;
          this.errors.push(error);
        });
  }

  onRecoveryChanged() {
    this.submitted = false;
  }

}
