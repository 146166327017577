import { DatePipe } from '@angular/common';
import { parseISO } from 'date-fns';
import * as moment from 'moment';

//---------Date Utils---------//
export const parseDate = (date: string): Date => {
    if(date == null) {
        return null;
    }
    const m = moment(date);
    return m.toDate();
};

export const formateDateFE = (date: string): string => {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'dd/MM/yyyy');
}

export const dateToStringFE = (date: Date): string => {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'dd/MM/yyyy');
};

export const getTimeString = (date: Date): string => {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'HH:mm');
};

export const parseTime = (time: string): Date => {
    const mom = moment(time, 'H:m');
    return mom.toDate();
};

export const timeToNextQuater = (date: Date): Date => {
    const datePipe = new DatePipe('en-US');
    let minAfterQuarter = Number(datePipe.transform(date, 'mm')) % 15;
    const mom = moment(date).add(15 - minAfterQuarter, 'm');
    return mom.toDate();
};

export const addTime = (date: Date, amount: number, unit: any): Date => {
    const mom = moment(date).add(amount, unit);
    return mom.toDate();
};

export const isDatesSame = (date1: Date, date2: Date, granularity): boolean => {
    if(date1 == null && date2 == null) {
        return true;
    }
    if(date1 == null || date2 == null) {
        return false;
    }
    if(moment(date2).isSame(date1, granularity)) {
        return true;
    }
    return false;
};

export const convertMsToHM = (milliseconds): string => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

//---------End Date Utils---------//
export const getLocalizedValue = (input: string) : {} => {
  return {"en_US": input, "it_IT": input};
}

export const emptyIfNull = (input: string) : string => {
  if(input) {
    return input;
  }
  return '';
}

export const setValueByCode = (code: string, domain: {code: string, label: string}[]): string => {
    return domain.filter(d => code === (d.code)).map(d => d.label)[0];
};

export const toBoolean = (input: string): boolean => {
  return (/true/i).test(input);
};

export const downloadFile = (file: Blob, filename: string) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
};

export const objToArray = (obj: any): any[] => {
    return Object.keys(obj).map(k => {
        let filter = {};
        filter[k] = obj[k];
        return filter;
    });
}

export const compareArraysId = (array1: any[], array2: any[]): boolean => {
    if(isEmpty(array1) && isEmpty(array2)) {
        return true;
    } else if (isEmpty(array1) || isEmpty(array2)) {
        return false;
    }
    if(array1.length != array2.length) {
        return false;
    }
    return array1.every(obj1 => array2.find(obj2 => obj2.id === obj1.id));
}

export const isEmpty = (array: any[]): boolean => {
    return (array == null || array.length === 0);
}

export const parseJSON = (jsonString: string) : any => {
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) { }
    return jsonString;
};

export const deepDiffMapper = function () {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      mapReturnDifference: function(obj1, obj2) {
        const mappedObject = this.map(obj1, obj2);
        return this.getDifference(mappedObject, true);
      },
      map: function(obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj2 // return new value
          };
        }
  
        var diff = {};
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }
          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }
          diff[key] = this.map(obj1[key], value2);
        }

        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }
          diff[key] = this.map(undefined, obj2[key]);
        }
        return diff;
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      getDifference: function (mappedObject, checkDeleted?: boolean) {
        var diff = {};
        for(var key in mappedObject) {
            if(!('type' in mappedObject[key]) && !('data' in mappedObject[key])) {
              let diffVal = this.checkDifference(mappedObject[key]);
              if (Object.keys(diffVal).length != 0) {
                diff[key] = diffVal;
              }
            }
            if(mappedObject[key].type == this.VALUE_UPDATED || mappedObject[key].type == this.VALUE_CREATED
              ||(checkDeleted && mappedObject[key].type == this.VALUE_DELETED)) {
                diff[key] = mappedObject[key].data;
            }
        }
        return diff;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      }
    }
  }();