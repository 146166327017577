import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionStorageService} from '../core/services/session-storage.service';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.sessionStorage.user;
    if (user) {
      if (!this.sessionStorage.hasPermission(route.data.routeKey)) {
        this.router.navigate(['error/401']);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
