<nb-card>
  <nb-card-header>{{'PROFILE.TELEGRAM.STATUS' | translate}}</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-6">
        {{"PROFILE.TELEGRAM.BOT_NAME" | translate}}
      </div>
      <div class="col-6">
        <ng-container>
          {{status.botName}}
        </ng-container>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        {{"PROFILE.TELEGRAM.PAIRING_STATUS" | translate}}
      </div>
      <div class="col-6">
        <ng-container *ngIf="telegramConnected">
          <nb-icon icon="checkmark-circle-2" style="color: green;"></nb-icon>
          {{"PROFILE.TELEGRAM.CONNECTED" | translate}}
        </ng-container>
        <ng-container *ngIf="!telegramConnected">
          <nb-icon icon="close-circle" style="color: red;"></nb-icon>
          {{"PROFILE.TELEGRAM.NOT_CONNECTED" | translate}}
        </ng-container>
      </div>
    </div>
    <div class="row mt-3" *ngIf="telegramConnected">
      <div class="col-6">
        {{"PROFILE.TELEGRAM.CHAT_ID" | translate}}
      </div>
      <div class="col-6">
        <ng-container>
          {{status.chatId}}
        </ng-container>
      </div>
    </div>
    <div class="row mt-3" *ngIf="!telegramConnected">
      <div class="col-6">
        {{"PROFILE.TELEGRAM.TIMER" | translate}}
      </div>
      <div class="col-6">
        <ng-container>
          {{ minutes | number:'2.0-0' }}:{{ (seconds % 60) | number:'2.0-0' }}
        </ng-container>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <button *ngIf="!telegramConnected" nbButton (click)="pairAccount()">{{"PROFILE.TELEGRAM.PAIR_ACCOUNT" | translate}}</button>
        <button *ngIf="telegramConnected" nbButton (click)="unpairAccount()">{{"PROFILE.TELEGRAM.UNPAIR_ACCOUNT" | translate}}</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
