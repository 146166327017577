import { Injectable } from '@angular/core';
import {ConfigService} from '../index';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseOutput} from '../models/base-output.class';
import {map} from 'rxjs/operators';
import {AbstractCrudService} from './abstract-crud.service';
import {SessionStorageService} from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LimitsService extends AbstractCrudService {

  constructor(config: ConfigService,
              http: HttpClient,
              sessionService: SessionStorageService,
  ) {
    super();
    this.config = config;
    this.http = http;
    this.sessionService = sessionService;
  }

  get baseUrl(): string {
    return 'heimdall/v1/heimdall/';
  }

  getUserCurrentAPIUsage(user: string): Observable<any> {
    return this.http.get<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl +
      'getUserCurrentAPIUsage/' + user).pipe(
      map(output => output.data),
    );
  }

}
