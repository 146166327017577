<nb-accordion>
  <nb-accordion-item [expanded]="documents && documents.length > 0">
    <nb-accordion-item-header>{{ title | translate}}</nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of documents">
            <nb-card>
              <nb-card-header>
                {{ item?.data?.filename }} ({{ item?.data?.metaData?.size | number:'.2' }} MB)
              </nb-card-header>
              <nb-card-body>
                <table class="table">
                  <tr>
                    <td class="text-center">
                      <button type="button" nbButton status="primary" class="btn" size="tiny"
                                (click)="download(item?.data?.metaData?.id, item?.data?.filename)">
                        <nb-icon icon="cloud-download-outline"></nb-icon> {{ 'UPLOAD.DOWNLOAD' | translate}}
                      </button>
                      <button type="button" nbButton status="danger" class="btn" size="tiny"
                                (click)="delete(item?.data?.metaData?.id, item?.data?.filename)">
                        <nb-icon icon="trash-2-outline"></nb-icon> {{ 'UPLOAD.DELETE' | translate}}
                      </button>
                    </td>
                  </tr>
                </table>
              </nb-card-body>
            </nb-card>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of queue">
          <nb-card>
            <nb-card-header>
              {{ item?.file?.name }} ({{ item?.file?.size/1024/1024 | number:'.2' }} MB)
              <span *ngIf="item.isSuccess"><nb-icon icon="checkmark-circle-2-outline" status="success"></nb-icon></span>
              <span *ngIf="item.isCancel"><nb-icon icon="slash-outline" status="warning"></nb-icon></span>
              <span *ngIf="item.isError"><nb-icon icon="alert-circle-outline" status="danger"></nb-icon></span>
            </nb-card-header>
            <nb-card-body>
              <table class="table">
                <tr>
                  <td *ngIf="uploader.options.isHTML5">
                    <div class="progress text-center" style="margin-bottom: 0;">
                      <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                    </div>
                  </td>
                  <td class="text-center">
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <button type="button" nbButton status="primary" class="btn"
                            (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                      <nb-icon icon="cloud-upload-outline"></nb-icon>
                    </button>
                    <button type="button" nbButton status="warning" class="btn"
                            (click)="item.cancel()" [disabled]="!item.isUploading">
                      <nb-icon icon="slash-outline"></nb-icon>
                    </button>
                    <button type="button" nbButton status="danger" class="btn"
                            (click)="item.remove()">
                      <nb-icon icon="trash-2-outline"></nb-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </nb-card-body>
          </nb-card>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngIf="uploadAllowed">
          <nb-card>
            <nb-card-header>
              {{'UPLOAD.UPLOAD_FILE' | translate}}
            </nb-card-header>
            <nb-card-body>
              <div ng2FileDrop
                   [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                   (fileOver)="fileOverBase($event)"
                   [uploader]="uploader"
                   class="drop-zone"
                   (onFileDrop)="onFileDrop($event)">
                {{'UPLOAD.DROP_FILE' | translate}}
              </div>

              <input type="file" ng2FileSelect [uploader]="uploader" (change)="onFileSelected()"/>
            </nb-card-body>
          </nb-card>
        </div>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>

