<nb-list style="margin-bottom: 10px;">
  <nb-list-item *ngFor="let contact of contactList">
      <div style="width: 100%;">
        <span> {{getDetails(contact)}} </span>
        <span style="float: right;">
          <button type="button" nbButton status="danger" class="btn" size="tiny"
              (click)="removeContact(contact)">
            {{ 'FORM.BUTTON.REMOVE' | translate}}
          </button>
        </span>
      </div>
  </nb-list-item>
</nb-list>

<ngx-component-search
  ngDefaultControl
  [control]="field.formControl"
  [name]="field.id"
  [id]="field.id"
  [required]="field.required"
  [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
  [placeholder]="field.showPlaceholder ? field.getLocalizedPlaceholder(locale) : ''"
  (selectionChange)="selectionChange($event)"
  [filters]="field.filterKeys"
  [labels]="field.labelKeys"
  [defaultFilters]="field.filters">
</ngx-component-search>
