import {Component, Input} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {delay} from 'rxjs/operators';
import {of} from 'rxjs';
import {TelegramService} from '../../../pages/profile/services/telegram.service';
import {AlertService} from '../../services/alert.service';
import {ConfirmDialogComponent} from '../simple/confirm-dialog.component';
import { timer, Subscription } from 'rxjs';

@Component({
  selector: 'ngx-telegram-status',
  templateUrl: './telegram-status.component.html',
  styleUrls: ['./telegram-status.component.scss'],
})
export class TelegramStatusComponent {
  status: any = {};
  minutes;
  seconds;

  private _username: string;
  private timerSubscription: Subscription;

  get telegramConnected(): boolean {
    return this.status.chatId != null;
  }

  constructor(
    private telegramService: TelegramService,
    private alertService: AlertService,
    private dialogService: NbDialogService,
    private translateService: TranslateService,
  ) { }

  updateStatus(): void {
    this.telegramService.getStatus(this._username)
      .subscribe(status => {
        this.status = status;
        const validityInMinutes = parseInt(status.validity, 10);
        this.startTimer(validityInMinutes);
      });
  }

  pairAccount() {
    window.open(this.status.startupLink);
    of(([])).pipe(
      delay(20000),
    ).subscribe(() => this.updateStatus());
  }

  unpairAccount() {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'CONFIRM_DIALOG.CONFIRM',
        text: 'PROFILE.TELEGRAM.UNPAIR_CONFIRM',
      },
    }).onClose.subscribe(confirm => {
      if (confirm) {
        this.telegramService.unpairAccount(this._username)
          .subscribe(success => {
            if (success) {
              this.updateStatus();
              this.alertService.addAlert('success', this.translateService.instant('PROFILE.TELEGRAM.UNPAIR_SUCCESS'));
            }
          });
      }
    });
  }

  get username(): string {
    return this._username;
  }

  @Input()
  set username(value: string) {
    this._username = value;
    if (this._username) {
      this.updateStatus();
    }
  }

  startTimer(validityInMinutes) {
    const countdown = timer(0, 1000); // Emette un valore ogni secondo
    const initialTotalSeconds = validityInMinutes * 60;

    this.timerSubscription = countdown.subscribe(secondsElapsed => {
      const remainingSeconds = initialTotalSeconds - secondsElapsed;
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;

      this.minutes = minutes;
      this.seconds = seconds;

      if (remainingSeconds <= 0) {
        this.timerSubscription.unsubscribe();
        location.reload();
      }
    });
  }


}
