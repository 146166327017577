import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AlertService} from '../services/alert.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService,
              private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
      event => event instanceof HttpResponse ? 'succeeded' : '',
      err => {
        if (err.status === 500 && err.error?.data?.type === '/errors/FBRException') {
          this.alertService.addAlert('danger', err.error.data.detail);
          return;
        }
        if (err.status !== 401) {
          this.alertService.addAlert('danger', err.message);
        }
      },
    ));
  }
}
