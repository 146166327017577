import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { DefaultFilter } from 'ng2-smart-table';
import { DatePipe } from '@angular/common';
@Component({
  template: `
    <input nbInput placeholder="{{column.title}}" [formControl]="inputControl" [nbDatepicker]="rangepicker">
    <nb-rangepicker #rangepicker format="dd/MM/yyyy"></nb-rangepicker>
  `,
})
export class DateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
  inputControl = new FormControl();

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.column && this.column['settings'] && this.column['settings']?.custom?.delay) {
      this.delay = this.column['settings']?.custom?.delay;
    } else {
      this.delay = 500;
    }
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
      )
      .subscribe((value: string) => {
        if (this.inputControl.status === 'VALID') {
          const datepipe: DatePipe = new DatePipe('en-US');
          const dateRange: {start: string, end: string} = {start: '', end: ''};
          let start = '';
          let end = '';
          if(value !== null) {
            start = datepipe.transform(this.inputControl.value.start, 'YYYYMMdd');
            end = datepipe.transform(this.inputControl.value.end, 'YYYYMMdd');
          }
          dateRange.start = start;
          dateRange.end = end;
          this.query =  'dateRange:' + JSON.stringify(dateRange);
          this.setFilter();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.query = changes.query.currentValue;
      this.inputControl.setValue(this.inputControl.value);
    }
  }
}
