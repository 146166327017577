<div [formGroup]="formgroup" class="row">
    <div class="col-md-6 col-xs-12">
      <div class="fink-field-wrapper">
        <label [for]="field.id">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
        <input #input nbInput fullWidth
            [formControlName]="'date'"
            [name]="field.id"
            [id]="field.id"
            [nbDatepicker]="datepicker"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            class="date-input"/>
        <nb-datepicker #datepicker
            format="dd/MM/yyyy"
            [min]="(field.dateRange && field.required) ? field.dateRange.minDate : null"
            [max]="(field.dateRange && field.required) ? field.dateRange.maxDate : null"
        ></nb-datepicker>
      </div>
    </div>
    <div class="col-6 col-md-3 mt-4 mt-md-0">
      <div class="fink-field-wrapper">
        <label for="from">{{'MEETING.FROM' | translate}}<span *ngIf="field.required">*</span></label>
        <nb-select fullWidth
            [formControlName]="'from'"
            [id]="'from'"
            [placeholder]="'HH:mm'"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            class="time-input">
           <nb-option *ngFor="let value of timeDomain" [value]="value.code">{{value.label}}</nb-option>
         </nb-select>
      </div>
    </div>
    <div class="col-6 col-md-3 mt-4 mt-md-0">
      <div class="fink-field-wrapper">
        <label for="to">{{'MEETING.TO' | translate}}<span *ngIf="field.required">*</span></label>
        <nb-select fullWidth
            [formControlName]="'to'"
            [id]="'to'"
            [placeholder]="'HH:mm'"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            class="time-input">
            <nb-option *ngFor="let value of timeDomain" [value]="value.code">{{value.label}}</nb-option>
        </nb-select>
      </div>
    </div>
</div>
