export class ContactStatus {

  label: string;
  order: number;

  constructor(label: string, order: number) {
    this.label = label;
    this.order = order;
  }


  public static INCOMPLETE: ContactStatus = new ContactStatus('INCOMPLETE', 10);
  public static LEAD: ContactStatus = new ContactStatus('LEAD', 20);
  public static CUSTOMER: ContactStatus = new ContactStatus('CUSTOMER', 30);
  public static DEAD_END: ContactStatus = new ContactStatus('DEAD_END', 30);

  public static getByLabel(label) {
    switch (label) {
      case 'INCOMPLETE': return this.INCOMPLETE;
      case 'LEAD': return this.LEAD;
      case 'CUSTOMER': return this.CUSTOMER;
      case 'DEAD_END': return this.DEAD_END;
      case null: return null;
      case undefined: return undefined;
      case '': return undefined;
      default: return new ContactStatus(label, 0);
    }
  }
}
