<div class="row">
    <div class="col-6">
        <div class="cdk-container" class="text-center">
            <label for="available">{{'FORM.AVAILABLE' | translate}}</label>
            <nb-card>
                <nb-card-body>
                    <div
                        id="available"
                        cdkDropList
                        [cdkDropListData]="available"
                        cdkDropListConnectedTo="selected"
                        class="cdk-list"
                        (cdkDropListDropped)="drop($event)">
                        <div
                            class="cdk-box"
                            *ngFor="let item of available"
                            [cdkDragData]="item"
                            cdkDrag>{{item.name}}</div>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
    <div class="col-6">
        <div class="cdk-container" class="text-center">
            <label for="selected">{{'FORM.SELECTED' | translate}}</label>
            <nb-card>
                <nb-card-body>
                    <div
                        id="selected"
                        cdkDropList
                        [cdkDropListData]="selected"
                        cdkDropListConnectedTo="available"
                        class="cdk-list"
                        (cdkDropListDropped)="drop($event)"
                        [cdkDropListEnterPredicate]="predicate">
                        <div
                            class="cdk-box"
                            *ngFor="let item of selected"
                            cdkDrag
                            [cdkDragData]="item">{{item.name}}</div>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>