import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { DefaultFilter } from 'ng2-smart-table';
@Component({
  template: `
  <nb-select multiple placeholder='{{column.title}}' [formControl]="inputControl" style='width: {{column.width}}'>
    <nb-option *ngFor="let option of options" [value]="option.value">
      {{option.title}}
    </nb-option>
  </nb-select>
  `,
})
export class ListFilterComponent extends DefaultFilter implements OnInit, OnChanges {
  inputControl = new FormControl();
  options: any[];
  constructor() {
    super();
  }

  ngOnInit() {
    if (this.column && this.column['settings']) {
      if (this.column['settings']?.custom?.delay) {
        this.delay = this.column['settings']?.custom?.delay;
      } else {
        this.delay = 500;
      }
      this.options = this.column['settings']?.filter?.custom?.list;
    }
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
      )
      .subscribe((value: any[]) => {
        if (value && value.length > 0) {
          this.query = 'array:' + value.join(';');
        } else {
          this.query = '';
        }
        this.setFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.query = changes.query.currentValue;
      this.inputControl.setValue(this.inputControl.value);
    }
  }
}
