<div class="logo-box">
  <img src="assets/images/logo/logo_primario_white_payoff.png" style="width: 100%">
</div>

<nb-card>
  <nb-card-body>
    <nb-auth-block>
      <h1 id="title" class="title">{{'LOST_PASSWORD.TITLE'|translate}}</h1>

      <nb-alert *ngIf="errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>Oh snap!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
        </ul>
      </nb-alert>

      <nb-alert *ngIf="messages?.length" outline="success" role="alert">
        <p class="alert-title"><b>Hooray!</b></p>
        <ul class="alert-message-list">
          <li *ngFor="let message of messages" class="alert-message">{{ message |translate }}</li>
        </ul>
      </nb-alert>

      <form (ngSubmit)="sendResetPassword()" #form="ngForm" aria-labelledby="title">

        <div class="form-control-group">
          <label class="label" for="input-username">{{'LOST_PASSWORD.USERNAME'|translate}}</label>
          <input nbInput
                 fullWidth
                 [(ngModel)]="user.username"
                 #username="ngModel"
                 name="username"
                 id="input-username"
                 pattern="[a-zA-Z0-9_\.]+"
                 placeholder="Username"
                 fieldSize="large"
                 autofocus
                 [status]="username.dirty ? (username.invalid  ? 'danger' : 'success') : 'basic'"
                 [required]="true"
                 [attr.aria-invalid]="username.invalid && username.touched ? true : null">
          <ng-container *ngIf="username.invalid && username.touched">
            <p class="caption status-danger" *ngIf="username.errors?.required">
              {{'LOST_PASSWORD.USERNAME_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="username.errors?.pattern">
              {{'LOST_PASSWORD.USERNAME_INVALID'|translate}}
            </p>
          </ng-container>
        </div>

        <div class="form-control-group">
          <label class="label" for="input-username">{{'LOST_PASSWORD.EMAIL'|translate}}</label>
          <input nbInput
                 type="email"
                 fullWidth
                 [(ngModel)]="user.email"
                 #email="ngModel"
                 name="email"
                 id="input-email"
                 placeholder="Email"
                 fieldSize="large"
                 autofocus
                 [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                 [required]="true"
                 [attr.aria-invalid]="email.invalid && email.touched ? true : null">
          <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.required">
              {{'LOST_PASSWORD.EMAIL_REQUIRED'|translate}}
            </p>
            <p class="caption status-danger" *ngIf="email.errors?.pattern">
              {{'LOST_PASSWORD.EMAIL_INVALID'|translate}}
            </p>
          </ng-container>
        </div>

        <div class="form-control-group">
          <label class="label" for="recovery-mode">{{'LOST_PASSWORD.RECOVERY_MODE'|translate}}</label>
          <nb-radio-group [(ngModel)]="user.recoveryMode" name="recoveryMode" id="recovery-mode" (valueChange)="onRecoveryChanged()">
            <nb-radio
              *ngFor="let option of recoveryOptions"
              [value]="option.value" style="display: inline;">
              {{ option.label }}
            </nb-radio>
          </nb-radio-group>
        </div>

        <button nbButton
                fullWidth
                status="primary"
                size="large"
                [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted">
          {{'LOST_PASSWORD.SEND_LINK'|translate}}
        </button>
      </form>
    </nb-auth-block>
  </nb-card-body>
</nb-card>
