import { StatusComponent } from './components/simple/status/status.component';
import { ListComponent } from './components/simple/list/list.component';
import { UploadComponent } from './components/upload/upload.component';
import { ListFilterComponent } from './components/simple/filter/list-filter.component';
import { TextFilterComponent } from './components/simple/filter/text-filter.component';
import { NumberFilterComponent } from './components/simple/filter/number-filter.component';
import { DateFilterComponent } from './components/simple/filter/date-filter.component';
import {NgModule} from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule, DecimalPipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorComponent} from './errors/error.component';
import {RouterOutlet} from '@angular/router';
import {CrmCoreRoutingModule} from './crm-core-routing.module';
import {UnauthorizedComponent} from './errors/unauthorized/unauthorized.component';
import {NotFoundComponent} from './errors/not-found/not-found.component';
import {ThemeModule} from '../@theme/theme.module';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbSelectModule,
    NbSpinnerModule,
    NbTimepickerModule,
    NbTooltipModule,
} from '@nebular/theme';
import {NbSecurityModule} from '@nebular/security';
import {FinkFieldsetComponent} from './components/fink-fieldset/fink-fieldset.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TimelineComponent} from './components/timeline/timeline.component';
import {TimelineItemComponent} from './components/timeline/timeline-item.component';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import { FinkSmartTableComponent } from './components/fink-smart-table/fink-smart-table.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {ContactSearchComponent} from './components/contact-search/contact-search/contact-search.component';
import {ConfirmDialogComponent} from './components/simple/confirm-dialog.component';
import { DateDurationComponent } from './components/simple/date-duration/date-duration.component';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { FileUploadModule } from 'ng2-file-upload';
import {TelegramStatusComponent} from './components/telegram-status/telegram-status.component';
import { DualListComponent } from './components/simple/dual-list/dual-list.component';
import { FieldFlagComponent } from './components/simple/field-checkbox/field-flag.component';
import { MultipleContactSearchComponent } from './components/contact-search/multiple-contact-search/multiple-contact-search.component';

@NgModule({
  declarations: [
    ErrorComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    FinkFieldsetComponent,
    TimelineComponent,
    TimelineItemComponent,
    FinkSmartTableComponent,
    DateFilterComponent,
    NumberFilterComponent,
    TextFilterComponent,
    ListFilterComponent,
    ContactSearchComponent,
    ConfirmDialogComponent,
    DateDurationComponent,
    UploadComponent,
    TelegramStatusComponent,
    StatusComponent,
    ListComponent,
    DualListComponent,
    FieldFlagComponent,
    MultipleContactSearchComponent,
  ],
    imports: [
        CrmCoreRoutingModule,
        ThemeModule,
        NbCardModule,
        NbButtonModule,
        Ng2SmartTableModule,
        CommonModule,
        HttpClientModule,
        RouterOutlet,
        NbSecurityModule.forRoot(),
        ReactiveFormsModule,
        NbInputModule,
        NbDatepickerModule,
        NbSelectModule,
        TranslateModule,
        NbIconModule,
        NbTimepickerModule.forRoot(),
        NbAutocompleteModule,
        NbDateFnsDateModule,
        FileUploadModule,
        NbAccordionModule,
        NbListModule,
        NbSpinnerModule,
        NbTooltipModule,
        DragDropModule,
        NbCheckboxModule
    ],
  exports: [
    FinkFieldsetComponent,
    TimelineComponent,
    TimelineItemComponent,
    FinkSmartTableComponent,
    DateFilterComponent,
    NumberFilterComponent,
    TextFilterComponent,
    ListFilterComponent,
    ContactSearchComponent,
    ConfirmDialogComponent,
    DateDurationComponent,
    UploadComponent,
    TelegramStatusComponent,
    StatusComponent,
    ListComponent,
    DualListComponent,
    MultipleContactSearchComponent,
  ],
  providers: [
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
})
export class CrmCoreModule {
}
