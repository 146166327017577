import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DocumentService } from '../../services/document.service';
import { LimitsService } from '../../services/limits.service';


@Component({
  selector: 'ngx-user-limits',
  templateUrl: './user-limits.component.html',
  styleUrls: ['./user-limits.component.scss'],
})
export class UserLimitsComponent implements OnInit {

  progressInfoData: any[] = [];
  docLimits: Observable<any>;
  limits: Observable<any>;
  private _username: any;
  private docLimitsSubscription: Subscription;
  private userLimitsSubscription: Subscription;
  constructor(private limintService: LimitsService,
              private documentSerive: DocumentService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
  }
  setlimits(): void {
    this.docLimits = this.documentSerive.getMemoryOccupation(this._username);
    this.docLimitsSubscription = this.docLimits.subscribe((data: any) => {
      const docValue = data.value;
      this.progressInfoData.push({
        title: 'USER_LIMITS.DOC.TITLE',
        value: docValue.toFixed(2) + '/100 MB',
        activeProgress: docValue,
        description : 'USER_LIMITS.DOC.DESC',
      });
    });
    this.limits = this.limintService.getUserCurrentAPIUsage(this._username);
    this.userLimitsSubscription = this.limits.subscribe((data: any) => {
      const limitsValue = data[0];
      this.progressInfoData.push({
        title: 'USER_LIMITS.STT.TITLE',
        value: limitsValue.totalSTTspeechDurationInSecond + '/1000 s',
        activeProgress: limitsValue.totalSTTspeechDurationInSecond / 10,
        description : 'USER_LIMITS.STT.DESC',
      });
      this.progressInfoData.push({
        title: 'USER_LIMITS.TTS.TITLE',
        value: limitsValue.totalTTScharsNumber + '/1000 n',
        activeProgress: limitsValue.totalSTTspeechDurationInSecond / 10,
        description : 'USER_LIMITS.TTS.DESC',
      });
      this.progressInfoData.push({
        title: 'USER_LIMITS.GEO.TITLE',
        value: limitsValue.totalGeocodeCalls + '/1000 n',
        activeProgress: limitsValue.totalSTTspeechDurationInSecond / 10,
        description : 'USER_LIMITS.GEO.DESC',
      });
    });
  }

  get username(): any {
    return this._username;
  }

  @Input()
  set username(value: any) {
    this._username = value;
    if (this._username) {
      this.setlimits();
    }
  }

}
