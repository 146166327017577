<div class="status incomplete" *ngIf="isCurrent(contactStatus.INCOMPLETE)" [nbTooltip]="'CONTACT.STATUS.INCOMPLETE' | translate">
    <div class="status-icon">
    <nb-icon icon="edit-outline"></nb-icon>
    </div>
</div>
<div class="status lead" *ngIf="isCurrent(contactStatus.LEAD)" [nbTooltip]="'CONTACT.STATUS.LEAD' | translate">
    <div class="status-icon">
    <nb-icon icon="corner-right-up"></nb-icon>
    <div class="substatus-icon" *ngIf="getSubStatus()">
        <nb-icon icon="calendar-outline"></nb-icon>
    </div>
    </div>
</div>
<div class="status customer" *ngIf="getStatus() === contactStatus.CUSTOMER" [nbTooltip]="'CONTACT.STATUS.CUSTOMER' | translate">
    <div class="status-icon">
    <nb-icon icon="checkmark-circle-outline"></nb-icon>
    <div class="substatus-icon" *ngIf="getSubStatus()">
        <nb-icon icon="shopping-bag-outline" ></nb-icon>
    </div>
    </div>
</div>
<div class="status dead-end" *ngIf="getStatus() === contactStatus.DEAD_END" [nbTooltip]="'CONTACT.STATUS.DEAD_END' | translate">
    <div class="status-icon">
    <nb-icon icon="close-outline" ></nb-icon>
    </div>
</div>