import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ErrorComponent} from './errors/error.component';
import {NotFoundComponent} from './errors/not-found/not-found.component';
import {UnauthorizedComponent} from './errors/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    component: ErrorComponent,
    children: [
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: '401',
        component: UnauthorizedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmCoreRoutingModule {
}
