import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Form, FormGroup} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {FormComponent} from '../../models/form-component.class';
import {FormComponentType} from '../../models/form-component-type.class';
import {TranslateService} from '@ngx-translate/core';
import {FormCompositeComponent} from '../../models/form-composite-component.class';

@Component({
  selector: 'ngx-fink-fieldset',
  templateUrl: './fink-fieldset.component.html',
})
export class FinkFieldsetComponent implements OnInit {
  private _formFields: FormCompositeComponent[] = [];
  private _formGroup: FormGroup;
  @Input() columns: number = 2;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  public formComponentType = FormComponentType;

  constructor(
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService) {

  }

  ngOnInit() {
  }


  get formFields(): FormCompositeComponent[] {
    return this._formFields.filter(f=> f.rendered);
  }

  @Input()
  set formFields(value: FormCompositeComponent[]) {
    if (value) {
      this._formFields = value.sort(FormComponent.sort);
    }
  }

  get formGroup(): FormGroup {
    return this._formGroup;
  }

  @Input()
  set formGroup(value: FormGroup) {
    this._formGroup = value;
  }

  get locale() {
    return this.translateService.currentLang;
  }

// fieldChanged(fieldName: string) {
  //   this.formFields.forEach(formField => {
  //     if (formField.name === fieldName && formField.changeEvent) {
  //       formField.changeEvent();
  //     }
  //   });
  //   // this.onChange.emit(fieldName);
  // }

  // dateChanged(newDate: Date, fieldIndex: string, fieldName: string) {
  //   if (!this.isDateDisabled(newDate)) {
  //     this.oldDates[fieldIndex] = newDate;
  //     this._formFields[fieldIndex].formControl.patchValue(newDate);
  //     this.onChange.emit(fieldName);
  //   } else {
  //     this._formFields[fieldIndex].formControl.patchValue(this.oldDates[fieldIndex]);
  //   }
  // }

  isDateDisabled(date: Date) {
    return date.getDay() === 6 || date.getDay() === 7;
  }

  getColumnClass(fullRow: boolean): string {
    if(fullRow) {
      return 'col-12';
    }
    switch (this.columns) {
      case 1:
        return 'col-12';
      case 2:
        return 'col-md-6 col-12';
    }
  }

  // handleFilter(value: string, fieldIndex: string) {
  //   this.formFields[fieldIndex].values.subscribe(values => {
  //     this.formFields[fieldIndex].filteredValues = values.filter((s) => s.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //   });
  // }
  //
  // handleComboFilter(value: string, fieldIndex: string) {
  //   this.formFields[fieldIndex].values.subscribe(values => {
  //     this.formFields[fieldIndex].filteredValues = values.filter((s) => s.descrizione.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //   });
  // }

  // isRequired(formControl: FormControl) {
  //   return hasRequiredField(formControl);
  // }

}
