import { downloadFile, parseJSON } from './../../utils/common-utils';
import { take } from 'rxjs';
import { DocumentService } from './../../services/document.service';
import { AlertService } from './../../services/alert.service';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import {CrmAuthService} from '../../../auth/auth.service';
import {FileItem} from 'ng2-file-upload/file-upload/file-item.class';
import {ParsedResponseHeaders} from 'ng2-file-upload/file-upload/file-uploader.class';
import {CrmDocument} from '../../models/document.class';
import {TranslateService} from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../simple/confirm-dialog.component';
import { NbDialogService } from '@nebular/theme';


@Component({
  selector: 'ngx-upload',
  styleUrls: ['./upload.component.scss'],
  templateUrl: './upload.component.html',
})
export class UploadComponent implements OnInit {

  private _documents: CrmDocument[] = [];

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  newDocumnet: CrmDocument;
  queue: FileItem[];
  @Input() title: string = 'UPLOAD.TITLE';
  @Input() clientId: string;
  @Input() clientIdRequired: boolean = true;
  @Input() url: string = '/api/document/v1/document/addDocument';
  @Input() alias: string = 'document';
  @Input() uploadAllowed: boolean = true;
  @Input() fileTypes: string[];

  get documents(): CrmDocument[] {
    return this._documents;
  }
  @Input()
  set documents(value: CrmDocument[]) {
    this._documents = value;
  }

  @Output() onUpload = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onError = new EventEmitter();

  constructor (
    private authService: CrmAuthService,
    private alertService: AlertService,
    private documentService: DocumentService,
    private translateService: TranslateService,
    private dialogService: NbDialogService,
  ) {}

  ngOnInit(): void {
    this.authService.getToken().subscribe(token => {
      this.uploader = new FileUploader({
        url: this.url,
        authToken: 'Bearer ' + token.toString(),
        method: 'POST',
        itemAlias: this.alias,
      });

      this.uploader.onErrorItem = (
        item: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders) => {
        this.response = response;
        let responseJson = parseJSON(response);
        if(responseJson && responseJson.data && responseJson.data.detail) {
          this.alertService.addAlert('danger', responseJson.data.detail);
        } else {
          this.alertService.addAlert('danger', 'Error during uploading file');
        }
        console.log('Error:' + status);
        this.onError.emit(response);
      };

      this.uploader.onSuccessItem = (
        item: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders) => {
        this.alertService.addAlert('success', this.translateService.instant('FORM.SAVE_SUCCESSFUL'));
        let responseJson = parseJSON(response);
        if (responseJson?.['data']?.id) {
          this.newDocumnet.data.metaData['id'] = responseJson['data'].id;
        }
        this.onUpload.emit(this.newDocumnet);
        console.log('Success:' + status);
      };

      this.uploader.onCompleteItem = (
        item: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders) => {
        this.uploader.queue = [];
        this.queue = [];
        console.log('Complete:' + status);
      };

      this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
        this.newDocumnet = new CrmDocument();
        this.newDocumnet.data = {
          filename: fileItem._file.name,
          clientId: this.clientId,
          metaData: {'size': fileItem._file.size/1024/1024},
        }
        form.append('body', JSON.stringify(this.newDocumnet));
        console.log(form.get('body'));
      };

      this.hasBaseDropZoneOver = false;

      this.response = '';

      this.uploader.response.subscribe( res => this.response = res );
    });
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public onFileDrop(event: any) {
    this.upload();
  }

  public onFileSelected() {
    this.upload();
  }

  upload() {
    if (this.fileTypes) {
      if (!this.fileTypes.includes(this.uploader.queue[0]._file.type)) {
        this.alertService.addAlert('danger', this.translateService.instant('UPLOAD.UNSUPPORTED_FILETYPE'));
        return;
      }
    }
    if (this.clientIdRequired && !this.clientId) {
      this.alertService.addAlert('danger', this.translateService.instant('UPLOAD.SELECT_CLIENT'));
      return;
    }
    let text = this.translateService.instant('UPLOAD.CONFIRM', {'document': this.uploader.queue[0]._file.name});
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'CONFIRM_DIALOG.CONFIRM',
        text: text,
      },
    }).onClose.subscribe(confirm => {
      if (confirm) {
        this.uploader.queue[0].upload();
      } else {
        this.uploader.queue = [];
      }
      this.queue = this.uploader.queue.filter(q => !q.isUploaded);
    });
  }

  download(id, filename) {
    const doc = this.documentService.getDocument(id).pipe(take(1)).subscribe(
      file => {
        console.log(file);
        downloadFile(file, filename);
      },
      err => {
        console.log('Error: ', err);
      }
  );
  }

  delete(id, name) {
    let text = this.translateService.instant('UPLOAD.CONFIRM_DELETE', {'document': name});
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'CONFIRM_DIALOG.CONFIRM',
        text: text,
      },
    }).onClose.subscribe(confirm => {
      if (confirm) {
        const doc = this.documentService.delete(id).pipe(take(1)).subscribe((data)=> {
          this.onDelete.emit(id);
        });
      }
    });
  }
}
