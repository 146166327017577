import { FormControl, FormGroup } from '@angular/forms';
import { FormCompositeComponent } from './../../../models/form-composite-component.class';
import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { getTimeString, parseTime } from '../../../utils/common-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-date-duration',
  templateUrl: './date-duration.component.html',
  styleUrls: ['./date-duration.component.scss'],
})
export class DateDurationComponent implements OnInit {

  @Input() field:  FormCompositeComponent;

  @ViewChild('input') input;

  formgroup: FormGroup;
  timeDomain: {code: string, label: string}[];

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.initTimeDomain();
    this.formgroup = this.field.formControl;
    this.formgroup.controls['from'].valueChanges.subscribe((value: any) => {
      if (value) {
        const defaultTo = {};
        const fromDate = parseTime(value);
        const fromPlusHalfHour =  new Date(fromDate.getTime() + (3600000 * 0.5))
        defaultTo['to'] = getTimeString(fromPlusHalfHour);
        this.formgroup.patchValue(defaultTo);
      }
    });
  }

  get locale() {
    return this.translateService.currentLang;
  }
  
  initTimeDomain() {
    this.timeDomain = [];
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');
      for (let j = 0; j < 60; j = j + 15) {
        const min = j.toString().padStart(2, '0');
        const code = hour + ':' + min;
        const label = hour + ':' + min;
        this.timeDomain.push({code: code, label: label});
      }
    }
    return this.timeDomain;
  }

}
