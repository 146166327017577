import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FinkDataSource } from './../../../data/fink.data.source';
import { FormCompositeComponent } from './../../../models/form-composite-component.class';
import { setValueByCode } from '../../../utils/common-utils';

@Component({
  selector: 'ngx-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnChanges {

  @Output() onAdd = new EventEmitter();
  @Output() onDetail = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  @Input() title;
  @Input() fields:  FormCompositeComponent[];
  @Input() dataSource: FinkDataSource;
  @Input() id: string;
  @Input() locale;
  elements: any[] = [];

  @Input() logoColumn: TemplateRef<any>;

  loading: boolean;
  finished;
  pageToLoadNext = 1;

  pageSize = 10;

  filters = new FormGroup({});
  filter = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.filters.addControl('filter', this.filter);
    this.filters.controls['filter'].valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((value: string) => {
        if (!value || value.length >= 3) {
          this.fields.forEach(f => {
            if (f.filterable) {
              this.dataSource.addFilter({
                field: f.modelKey,
                search: value,
              }, true, false);
            }
          });
          this.loading = true;
          this.elements = [];
          this.pageToLoadNext = 1;
          this.dataSource.setPage(this.pageToLoadNext, false);
          this.updateData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSource) {
      this.dataSource.filterOr = true;
      this.dataSource.setPaging(this.pageToLoadNext, this.pageSize, false);
      this.updateData();
      this.dataSource.createRequesParams();
    }
  }

  updateData() {
    this.dataSource.getElements()
        .then(
          (elements) => {
            if (elements.length > 0) {
              this.updateElements(elements);
              this.pageToLoadNext++;
              this.finished = this.elements.length >= this.dataSource.getLastRequestCount();
            } else {
              this.finished = true;
            }
            this.loading = false;
          },
          (err) => {
            this.loading = false;
          },
        );
  }

  updateElements(elements : any[]) {
    elements.forEach(element => {
      if (this.elements.findIndex(e => e[this.id] === element[this.id]) === -1) {
        this.elements.push(element);
      }
    });
  }

  loadNext() {
    if (this.loading || this.finished) { return; }
    this.loading = true;
    if (this.dataSource) {
      this.dataSource.setPage(this.pageToLoadNext, false);
      this.updateData();
    } else {
      this.loading = false;
    }
  }

  detail(element: any) {
    const event = {};
    event['data'] = element;
    this.onDetail.emit(event);
  }

  add() {
    this.onAdd.emit();
  }
  
  select(element) {
    this.elements.filter(e => e.selected).forEach(e => e.selected = null);
    element.selected = true;
    this.onSelect.emit(element);
  }
  
  getColumnClass(): string {
    if(this.logoColumn) {
      return 'col-8';
    } else {
      return 'col-10';
    }
  }

  getValue(element, field) {
    if(field.type === 'DOMAIN') {
      return setValueByCode(element[field.modelKey], field.getLocalizedValues(this.locale));
    }
    return element[field.modelKey];
  }
}
