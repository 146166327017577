import { map } from 'rxjs/operators';
import { BaseOutput } from '../../core/models/base-output.class';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractCrudService} from './abstract-crud.service';
import {SessionStorageService} from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends AbstractCrudService {

  constructor(config: ConfigService,
              http: HttpClient,
              sessionService: SessionStorageService,
  ) {
    super();
    this.config = config;
    this.http = http;
    this.sessionService = sessionService;
  }

  get baseUrl(): string {
    return 'document/v1/document/';
  }

  getDocument(idAttachment: string): Observable<any> {
    return this.http.get(this.config.getApiUrl() + this.baseUrl +
      'getDocument/' + idAttachment, {responseType: 'blob'} );
  }

  getMemoryOccupation(user: string): Observable<any> {
    return this.http.get(this.config.getApiUrl() + this.baseUrl +
      'getMemoryOccupation/' + user);
  }
}
