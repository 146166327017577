import { Injectable } from '@angular/core';
import {ConfigService} from '../../../core';
import {HttpClient} from '@angular/common/http';
import {Observable, take} from 'rxjs';
import {FormField} from '../../../core/models/form-field.class';
import {BaseOutput} from '../../../core/models/base-output.class';
import {map} from 'rxjs/operators';
import {BaseInput} from '../../../core/models/base-input.class';
import {AbstractCrudService} from '../../../core/services/abstract-crud.service';
import {SessionStorageService} from '../../../core/services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends AbstractCrudService {

  constructor(config: ConfigService,
              http: HttpClient,
              sessionService: SessionStorageService,
  ) {
    super();
    this.config = config;
    this.http = http;
    this.sessionService = sessionService;
  }

  get baseUrl(): string {
    return 'client/v1/client/';
  }

  get publicUrl(): string {
    return '/client/public/';
  }

  getRelations(id: string): Observable<any> {
    return this.http.get<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl + id + '/relations').pipe(
      map(output => output.data),
    );
  }

  downloadTemplate(): Observable<any> {
    return this.http.get(this.config.getApiUrl() + this.publicUrl + 'download/v1/excel', {responseType: 'blob'});
  }
}
