import { Injectable } from '@angular/core';
import {ConfigService} from '../../core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, take} from 'rxjs';
import {BaseOutput} from '../../core/models/base-output.class';
import {map} from 'rxjs/operators';
import {BaseInput} from '../../core/models/base-input.class';
import {SessionStorageService} from './session-storage.service';

export abstract class AbstractCrudService {

  protected config: ConfigService;
  protected sessionService: SessionStorageService;
  http: HttpClient;

  abstract get baseUrl(): string;

  get idField(): string {
    return 'id';
  }

  get serviceUrl(): string {
    return this.config.getApiUrl() + this.baseUrl;
  }

  get baseFilters(): Observable<string> {
    return this.sessionService.userObservable.pipe(
      take(1),
      map(user => user != null ? ('(' + user.groups.map(group => 'groupId eq ' + group.id).join(' or ') + ')') : null),
    );
  }

  getData(id: string): Observable<any> {
    return this.http.get<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl + id + '/').pipe(
      map(output => output.data),
    );
  }

  readMulti(input: string, filter: string, sorting: string, pagination: string): Observable<any> {
    // Convert input, filter, sorting, and pagination into an object.
    const paramsObject = { input, filter, sorting, pagination };

    // Create a new instance of HttpParams.
    let params = new HttpParams();

    // Set each parameter in the HttpParams instance.
    Object.keys(paramsObject).forEach(key => {
      const value = paramsObject[key];
      if (value) {
        params = params.set(key, value);
      }
    });

    // Send the HTTP GET request with the query parameters.
    return this.http.get<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl, { params }).pipe(
      map(output => output.data),
    );
  }

  saveData(data: any): Observable<any> {
    const baseInput: BaseInput<any> = new BaseInput(data);
    return this.http.post<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl, baseInput).pipe(
      map(output => output.data),
    );
  }

  updateData(id: any, data: any): Observable<any> {
    const baseInput: BaseInput<any> = new BaseInput(data);
    return this.http.put<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl + id + '/', baseInput).pipe(
      map(output => output.data),
    );
  }

  delete(id: any): Observable<any> {
    return this.http.delete<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl + id + '/').pipe(
      map(output => output.data),
    );
  }

}
