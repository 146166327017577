import { Component, OnInit } from '@angular/core';
import {CrmAuthStrategy} from '../strategy/crm-auth-strategy.class';
import {Router} from '@angular/router';
import {CrmAuthService} from '../auth.service';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class CrmLogoutComponent implements OnInit {

  constructor(
    private authService: CrmAuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.logout(CrmAuthStrategy.STRATEGY_NAME).subscribe(result => this.router.navigate(['/auth/login']));
  }

}
