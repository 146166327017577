<nb-card>
  <nb-card-header>
    {{'USER_LIMITS.TITLE' | translate}}
  </nb-card-header>
  <nb-card-body>
    <div class="progress-info" *ngFor="let item of progressInfoData">
      <div class="subtitle">{{ item.title | translate}}: {{ item.value }} (<em>{{ item.description | translate }}</em>)</div>
      <nb-progress-bar [value]="item.activeProgress"></nb-progress-bar>
      <br/>
    </div>
  </nb-card-body>
</nb-card>

