import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {tap} from 'rxjs/operators';
import {SessionStorageService} from '../core/services/session-storage.service';
import {CrmAuthStrategy} from './strategy/crm-auth-strategy.class';
import {PermissionGuard} from './permission-guard.service';
import {CrmAuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: CrmAuthService,
    private crmAuthStrategy: CrmAuthStrategy,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private permissionGuard: PermissionGuard) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
            return false;
          } else {
            if (this.sessionStorage.user === null) {
              return this.crmAuthStrategy.sessionData().subscribe(response => {
                this.sessionStorage.user = response.data;
                if (route.data.routeKey) {
                  return this.permissionGuard.canActivate(route, state);
                }
              }, error => {
                this.router.navigate(['auth/login']);
                return false;
              });
            } else {
              this.sessionStorage.userSubject.next(this.sessionStorage.user);
            }
          }
        }),
      );
  }
}
