import { ListFilterComponent } from '../simple/filter/list-filter.component';
import { TextFilterComponent } from './../simple/filter/text-filter.component';
import { NumberFilterComponent } from '../simple/filter/number-filter.component';
import { DateFilterComponent } from '../simple/filter/date-filter.component';
import { formateDateFE, setValueByCode } from './../../utils/common-utils';
import { FormCompositeComponent } from './../../models/form-composite-component.class';
import {LocalDataSource, Ng2SmartTableComponent} from 'ng2-smart-table';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, SimpleChange, EventEmitter, Output } from '@angular/core';
import { FinkDataSource } from '../../../core/data/fink.data.source';

@Component({
  selector: 'fink-smart-table',
  templateUrl: './fink-smart-table.component.html',
  styleUrls: ['./fink-smart-table.component.scss'],
})
export class FinkSmartTableComponent implements OnInit, OnChanges {

  @Output() onDetail = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  
  @Input() source: FinkDataSource;
  @Input() locale;

  @Input() addButton: boolean = false;
  @Input() deleteButton: boolean = false;
  @Input() editButton: boolean = false;

  @ViewChild(Ng2SmartTableComponent) smartTable: Ng2SmartTableComponent;

  @Input()
  set commands(compositeCommands: FormCompositeComponent[]) {
    const settings = this.settings;
    if(compositeCommands) {
      settings.actions.delete = false;
      compositeCommands.forEach(command => {
        if(command.key === 'add') {
          settings.actions.add = command.rendered;
        }
        if(command.key === 'detail') {
          settings.actions.edit = command.rendered;
        }
        if(command.key === 'delete') {
          settings.actions.delete = command.rendered;
        }
      });
    }
    this.settings = settings;
  }

  @Input()
  set columns(compositeColumns: FormCompositeComponent[]) {
    const settings = this.settings;
    const columns = {};
    if (compositeColumns) {
      compositeColumns.forEach(column => {
        const modelKey = column.modelKey;
        columns[modelKey] = {};
        columns[modelKey].title = column.getLocalizedLabel(this.locale);
        columns[modelKey].hide = !column.rendered;
        columns[modelKey].editable = !column.readonly;
        if (column.metadata['width']) {
          columns[modelKey].width = column.metadata['width'];
        }
        columns[modelKey].type = column.type;
        if (column.type === 'DATE') {
          columns[modelKey].valuePrepareFunction = cell => formateDateFE(cell);
          columns[modelKey].filter = {
            type: 'custom',
            component: DateFilterComponent
          };
        } else if (column.type === 'DOMAIN') {
          columns[modelKey].valuePrepareFunction = cell => setValueByCode(cell, column.getLocalizedValues(this.locale));
          columns[modelKey].filter = {
            type: 'custom',
            component: ListFilterComponent,
            custom: {
              selectText: 'Select...',
              list: column.getLocalizedValues(this.locale).map(v=>{return {value: v.code, title: v.label}}),
            },
          };
        } else {
          columns[modelKey].filter = {
            type: 'custom',
            component: TextFilterComponent,
          };
        }
      });
    }
    settings.columns = columns;
    this.settings = settings;
  }

  private _settings = {
    mode: 'external',
    actions: {
      add: true,
      delete: true,
      edit: true,
      position: 'left',
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-arrow-right"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns : {},
  };

  get settings() {
    return this._settings;
  }

  set settings(settings) {
    this._settings = settings;
    if (this.smartTable) {
      this.smartTable.ngOnChanges({
        settings: new SimpleChange('oldSettings', 'newSettings', false),
      });
    }
  }

  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
  }

  onEdit(event){
    this.onDetail.emit(event);
  }

  onCreate(event){
    this.onAdd.emit(event);
  }
}

