import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {NbThemeService} from '@nebular/theme';
import {Subject} from 'rxjs';

@Component({
  selector: 'ngx-masonry-container',
  templateUrl: './masonry-container.component.html',
  styleUrls: ['./masonry-container.component.scss'],
})
export class MasonryContainerComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  numColumns: number = 4;
  colsAsIterable: number[] = [1, 2, 3, 4];

  @Input()
  template: TemplateRef<any> = null;

  private _items: any[];

  @Input()
  set items(value: any[]) {
    this._items = value;
  }
  get items(): any[] {
    return this._items;
  }

  constructor(
    private themeService: NbThemeService,
  ) { }

  ngOnInit(): void {

    this.themeService.onMediaQueryChange()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(([, currentBreakpoint])  => {
        switch (currentBreakpoint.name) {
          case 'is':
            this.numColumns = 1;
            break;
          case 'sm':
            this.numColumns = 2;
            break;
          case 'md':
            this.numColumns = 3;
            break;
          case 'lg':
          default:
            this.numColumns = 4;
            break;
        }
        this.colsAsIterable = Array.apply(null, Array(this.numColumns)).map(function () {});
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
