import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {User} from '../models/user.class';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private _userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _user: User;

  constructor() {
    // JSON.parse(localStorage.getItem('user'))
    this._userSubject = new BehaviorSubject<User>(null);
  }

  public get user(): User {
    this._userSubject
      .subscribe(user => this._user = user);
    return this._user;
  }

  public set user(userx: User) {
    if (userx === null) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', JSON.stringify(userx));
    }
    this._userSubject.next(userx);
  }

  public get userSubject(): Subject<User> {
    return this._userSubject;
  }

  public get userObservable(): Observable<User> {
    return this._userSubject.asObservable().pipe(
      filter(user => user != null),
    );
  }

  hasPermission(permission: string): boolean {
    if (this.user != null && this.user.roles && this.user.roles.length > 0) {
      const permissions: string[] = this.user.roles[0].operations
        .filter(op => op.id.indexOf('web.') === 0)
        .map(op => op.id.replace('web.', ''));
      return permissions.indexOf(permission) !== -1;
    }
  }

}
