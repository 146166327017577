<div class="logo-box">
  <img src="assets/images/logo/logo_primario_white_payoff.png" style="width: 100%">
</div>

<nb-card>
  <nb-card-body>
    <nb-auth-block>
      <div *ngIf="!valid" class="text-center">
        <nb-alert outline="danger" role="alert">
          <p class="alert-title"><b>{{'ERROR'|translate}}!</b></p>
          <ul class="alert-message-list">
            <li class="alert-message">
              {{ 'LOGIN.LINK_EXPIRED' | translate}}
            </li>
          </ul>
        </nb-alert>
        <p>
          <button nbButton
                  status="primary"
                  size="large"
                  (click)="goToLogin()">
            {{ 'RESET_PASSWORD.GO_TO_LOGIN' |translate }}
          </button>
        </p>
      </div>
    </nb-auth-block>
  </nb-card-body>
</nb-card>
