import { Injectable } from '@angular/core';
import {ConfigService} from '../../../core';
import {HttpClient} from '@angular/common/http';
import {AbstractCrudService} from '../../../core/services/abstract-crud.service';
import {SessionStorageService} from '../../../core/services/session-storage.service';
import {Observable} from 'rxjs';
import {BaseInput} from '../../../core/models/base-input.class';
import {Group} from '../../../core/models/group.class';
import {Role} from '../../../core/models/role.class';
import {User} from '../../../core/models/user.class';

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractCrudService {

  constructor(config: ConfigService,
              http: HttpClient,
              sessionService: SessionStorageService,
  ) {
    super();
    this.config = config;
    this.http = http;
    this.sessionService = sessionService;
  }

  get baseUrl(): string {
    return 'auth/v1/userx/';
  }

  get baseFilters(): Observable<string> {
    return new Observable<string>();
  }

  updateGroups(username: string, groups: Group[]): Observable<any> {
    const data: BaseInput<Group[]> = new BaseInput<Group[]>(groups);
    return this.http.put(this.config.getApiUrl() + this.baseUrl + username + '/groups', data);
  }

  updateRoles(username: string, roles: Role[]): Observable<any> {
    const data: BaseInput<Role[]> = new BaseInput<Role[]>(roles);
    return this.http.put(this.config.getApiUrl() + this.baseUrl + username + '/roles', data);
  }

  createUser(user: User): Observable<any> {
    const data: BaseInput<User> = new BaseInput<User>(user);
    return this.http.post(this.config.getApiUrl() + this.baseUrl + 'create', data);
  }

  updateUser(user: User): Observable<any> {
    const data: BaseInput<User> = new BaseInput<User>(user);
    return this.http.put(this.config.getApiUrl() + this.baseUrl + user.username  + '/update', data);
  }

  sendResetLink(user: User): Observable<any> {
    return this.http.put(this.config.getApiUrl() + this.baseUrl + user.username  + '/sendResetLink', {});
  }

}
