import { Injectable } from '@angular/core';
import {ConfigService} from '../../../core';
import {HttpClient} from '@angular/common/http';
import {BaseOutput} from '../../../core/models/base-output.class';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  constructor(private config: ConfigService,
              private http: HttpClient,
  ) {
  }

  get baseUrl(): string {
    return 'auth/v1/telegram/';
  }

  getStatus(username: string): Observable<any> {
    return this.http.get<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl + username + '/status').pipe(
      map(output => output.data),
    );
  }

  unpairAccount(username: string): Observable<any> {
    return this.http.post<BaseOutput<any>>(this.config.getApiUrl() + this.baseUrl + username + '/unpair', {}).pipe(
      map(output => output.data),
    );
  }

}
