<div class="row">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3 masonry-column" *ngFor="let col of colsAsIterable; index as i">
    <div *ngFor="let item of items | masonry : numColumns : i + 1" >
      <ng-template
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          item: item
        }"
      >
      </ng-template>
    </div>
  </div>
</div>
