import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import {map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {ConfigService} from '../../../core/services/config.service';
import {SessionStorageService} from '../../../core/services/session-storage.service';
import {LocaleService} from '../../../core/services/locale.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ReminderService} from '../../../pages/reminders/services/reminders.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showSearch = false;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu;

  userName: string;

  reminderPresent: Observable<boolean>;

  locale: string;
  supportedLocales: any[];

  otp: string;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private breakpointService: NbMediaBreakpointsService,
              private configService: ConfigService,
              private sessionService: SessionStorageService,
              private localeService: LocaleService,
              private translateService: TranslateService,
              private reminderService: ReminderService,
              private router: Router) {
    this.updateProfileMenu();
  }

  updateProfileMenu() {
    this.userMenu = [{
      title: this.translateService.instant('PROFILE.USER_PROFILE'),
      link: '/pages/profile',
    }, {
      title: this.translateService.instant('PROFILE.LOGOUT'),
      link: '/auth/logout',
    }];
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(evt => this.updateProfileMenu());

    this.reminderPresent = this.sessionService.userObservable.pipe(
      tap(user => this.userName = user.firstname + ' ' + user.lastname),
      mergeMap(user => {
        const filterString = 'ownerId eq ' + user.username;
        return this.reminderService.readMulti(null, filterString, null, null);
      }),
      map(reminders => reminders.length > 0),
    );

    this.supportedLocales = this.localeService.supportedLocales;
    this.locale = this.localeService.getCurrentLocale();

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

  }

  isPageUrl() {
    return this.router.url.indexOf('/pages/') === 0;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateLocale() {
    this.localeService.setLocale(this.locale);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
