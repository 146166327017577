import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NbAuthComponent} from '@nebular/auth';
import {CrmLoginComponent} from './login/login.component';
import {CrmJwtLoginComponent} from './jwt-login/jwt-login.component';
import {CrmLogoutComponent} from './logout/logout.component';
import {CrmLostPasswordComponent} from './lost-password/lost-password.component';
import {CrmResetPasswordComponent} from './reset-password/reset-password.component';
import {CrmAuthComponent} from './auth.component';
import { CrmUpdatePasswordComponent } from './update-password/update-password.component';

export const routes: Routes = [
  {
    path: '',
    component: CrmAuthComponent,
    children: [
      {
        path: 'login',
        component: CrmLoginComponent, // <---
      },
      {
        path: 'logout',
        component: CrmLogoutComponent, // <---
      },
      {
        path: 'lost-password',
        component: CrmLostPasswordComponent, // <---
      },
      {
        path: 'reset-password',
        component: CrmResetPasswordComponent, // <---
      },
      {
        path: 'jwt-login',
        component: CrmJwtLoginComponent, // <---
      },
      {
        path: 'update-password',
        component: CrmUpdatePasswordComponent, // <---
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmAuthRoutingModule {
}
