<nb-list nbInfiniteList
    listenWindowScroll
    [threshold]="500"
    (bottomThreshold)="loadNext()">
    <nb-list-item>
        <div [formGroup]="filters" class="col-10"  style="width: 100%;">
            <input 
                #filter
                nbInput
                fullWidth
                type="text"
                [placeholder]="'FORM.SEARCH' | translate"
                [formControlName]="'filter'">
        </div>
        <div id="addButton" class="col-2 action" style="width: 90px;">
            <button nbButton fullWidth status="primary" (click)="add()">
                <i class="nb-plus"></i>
            </button>
        </div>
    </nb-list-item>
    <nb-list-item *ngFor="let element of elements" class="item" [ngClass]="{'selected':element.selected}" (click)="select(element)">
        <ng-container 
            [ngTemplateOutlet]="logoColumn" 
            [ngTemplateOutletContext]="{ element: element }"
        ></ng-container>
        <div [class]="getColumnClass()">
            <div *ngFor="let field of fields" [ngClass]="{'inline': !field.fullRow}">
                {{getValue(element, field)}}
            </div>
        </div>
        <div class="col-2 action text-center">
            <i (click)="detail(element)" class="nb-arrow-right"></i>
        </div>
    </nb-list-item>
    <nb-list-item [nbSpinner]="true" nbSpinnerStatus="primary" nbSpinnerSize="giant" *ngIf="!finished">
    </nb-list-item>
</nb-list>