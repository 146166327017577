
<div class="fink-fieldset-container row" [formGroup]="formGroup">
  <div *ngFor="let field of formFields" [ngSwitch]="field.type"
    [class]="getColumnClass(field.fullRow)">
      <ng-container *ngSwitchCase="formComponentType.STRING">
        <div class="fink-field-wrapper">
            <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
            <input nbInput fullWidth type="text"
                  [formControlName]="field.modelKey"
                  [name]="field.id"
                  [id]="field.id"
                  [required]="field.required"
                  [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
                  [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
                  [readonly]="field.readonly"/>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_PATTERN_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.TEXTAREA">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <textarea nbInput fullWidth
                [rows]="field.rows"
                [formControlName]="field.modelKey"
                [name]="field.id"
                [id]="field.id"
                [required]="field.required"
                [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
                [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"></textarea>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_PATTERN_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.EMAIL">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth type="email"
                [formControlName]="field.modelKey"
                [name]="field.id"
                [id]="field.id"
                [required]="field.required"
                [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
                [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
                [readonly]="field.readonly"/>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_EMAIL_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.maxlength">
            {{'FORM.VALIDATION_ERRORS.INVALID_LENGTH_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.PRICE">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth type="price"
                [formControlName]="field.modelKey"
                [name]="field.id"
                [id]="field.id"
                [required]="field.required"
                [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
                [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
                [readonly]="field.readonly"/>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_PATTERN_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.PHONE">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth type="text"
                [formControlName]="field.modelKey"
                [name]="field.id"
                [id]="field.id"
                [required]="field.required"
                [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
                [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
                [readonly]="field.readonly"/>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_PATTERN_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.maxlength">
            {{'FORM.VALIDATION_ERRORS.INVALID_LENGTH_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.DATE">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth
            [formControlName]="field.modelKey"
            [name]="field.id"
            [id]="field.id"
            [nbDatepicker]="datepicker"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            [readonly]="field.readonly"/>
          <nb-datepicker #datepicker
            format="dd/MM/yyyy"
            [min]="(field.dateRange && field.required) ? field.dateRange.minDate : null"
            [max]="(field.dateRange && field.required) ? field.dateRange.maxDate : null"
          ></nb-datepicker>
          <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
            <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
              {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
            </p>
            <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
              Username should be the real one!
            </p>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.DATETIME">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth
            [formControlName]="field.modelKey"
            [name]="field.id"
            [id]="field.id"
            [nbDatepicker]="datetimepicker"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            [readonly]="field.readonly"/>
          <nb-date-timepicker #datetimepicker singleColumn [step]="15"
            format="dd/MM/yyyy HH:mm"
            [min]="(field.dateRange && field.required) ? field.dateRange.minDate : null"
            [max]="(field.dateRange && field.required) ? field.dateRange.maxDate : null"
          ></nb-date-timepicker>
          <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
            <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
              {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
            </p>
            <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
              The pattern is not correct!
            </p>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.DURATION">
        <div class="fink-field-wrapper">
          <ngx-date-duration [field]="field"></ngx-date-duration>
          <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
            <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
              {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
            </p>
            <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
              The pattern is not correct!
            </p>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.TIME">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth
            [formControlName]="field.modelKey"
            [name]="field.id"
            [id]="field.id"
            [nbTimepicker]="timepicker"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            [readonly]="field.readonly"/>
          <nb-timepicker
            format="hh:mm"
            #timepicker
            singleColumn
            [step]="15">
          </nb-timepicker>
          <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
            <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
              {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
            </p>
            <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
              The pattern is not correct!
            </p>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.DOMAIN">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <nb-select fullWidth
            [formControlName]="field.modelKey"
            [id]="field.id"
            [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [required]="field.required"
            (selectedChange)="field.listnerFunction($event)">
            <nb-option>...</nb-option>
            <nb-option *ngFor="let value of field.domain" [value]="value.code">{{value.label[locale]}}</nb-option>
          </nb-select>
          <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
            <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
              {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
            </p>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.PASSWORD">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <input nbInput fullWidth type="password"
                [formControlName]="field.modelKey"
                [name]="field.id"
                [id]="field.id"
                [required]="field.required"
                [placeholder]="field.showPlaceholder ? field.getLocalizedLabel(locale) : ''"
                [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
          />
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_PASSWORD_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.CONTACT_SELECT">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <ngx-component-search
            ngDefaultControl
            [control]="field.formControl"
            [formControlName]="field.modelKey"
            [name]="field.id"
            [id]="field.id"
            [required]="field.required"
            [status]="(field.formControl.invalid && field.formControl.touched) ? 'danger' : 'basic'"
            [placeholder]="field.showPlaceholder ? field.getLocalizedPlaceholder(locale) : ''"
            (selectionChange)="field.listnerFunction($event)"
            [filters]="field.filterKeys"
            [labels]="field.labelKeys"
            [defaultFilters]="field.filters">
          </ngx-component-search>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
          <p class="caption status-danger" *ngIf="field.formControl.errors?.pattern">
            {{'FORM.VALIDATION_ERRORS.INVALID_CONTACT'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.UPLOAD">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <ngx-upload
            [documents]="documents"
            (onUpload)="field.listnerFunction($event)">
          </ngx-upload>
        </div>
        <ng-container *ngIf="field.formControl.invalid && field.formControl.touched">
          <p class="caption status-danger" *ngIf="field.formControl.errors?.required">
            {{'FORM.VALIDATION_ERRORS.REQUIRED_FIELD'| translate: field.getTranslateValues(locale) }}
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.DUAL_LIST">
        <div class="fink-field-wrapper">
          <label [for]="field.id">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <ngx-dual-list
            [formControl]="field.formControl">
          </ngx-dual-list>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.FIELD_FLAG">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <ngx-field-flag [fieldInput]="field" [formGroup]="formGroup">
          </ngx-field-flag>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="formComponentType.MULTIPLE_CONTACTS">
        <div class="fink-field-wrapper">
          <label [for]="field.id" *ngIf="field.labelRendered">{{field.getLocalizedLabel(locale)}}<span *ngIf="field.required">*</span></label>
          <ngx-multiple-component-search
            [field]="field"
            [formGroup]="formGroup">
          </ngx-multiple-component-search>
        </div>
      </ng-container>
<!--    <ng-container *ngSwitchCase="fieldType.Numeric">-->
<!--      <div class="fink-field-wrapper">-->
<!--        <label [for]="field.value.name">{{field.value.description}}<span *ngIf="isRequired(field.value.formControl)">*</span></label>-->
<!--        <kendo-numerictextbox type="text"-->
<!--               [formControlName]="field.value.name"-->
<!--               ([name])="field.value.name"-->
<!--               [id]="field.value.name"-->
<!--               (input)="fieldChanged(field.value.name)"-->
<!--               [required]="isRequired(field.value.formControl)"-->
<!--               [readonly]="field.value.readonly">-->
<!--        </kendo-numerictextbox>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngSwitchCase="fieldType.NumericId">-->
<!--      <div class="fink-field-wrapper">-->
<!--        <label [for]="field.value.name">{{field.value.description}}<span *ngIf="isRequired(field.value.formControl)">*</span></label>-->
<!--        <kendo-numerictextbox type="text"-->
<!--                              [formControlName]="field.value.name"-->
<!--                              ([name])="field.value.name"-->
<!--                              [id]="field.value.name"-->
<!--                              [format]="'n0'"-->
<!--                              [step]="1"-->
<!--                              [decimals]="0"-->
<!--                              (input)="fieldChanged(field.value.name)"-->
<!--                              [required]="isRequired(field.value.formControl)"-->
<!--                              [readonly]="field.value.readonly">-->
<!--        </kendo-numerictextbox>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngSwitchCase="fieldType.Autocomplete">-->
<!--      <div class="fink-field-wrapper">-->
<!--        <label [for]="field.value.name">{{field.value.description}}<span *ngIf="isRequired(field.value.formControl)">*</span></label>-->
<!--        <kendo-autocomplete-->
<!--          [data]="field.value.filteredValues"-->
<!--          [formControlName]="field.value.name"-->
<!--          ([name])="field.value.name"-->
<!--          [id]="field.value.name"-->
<!--          [filterable]="true"-->
<!--          (filterChange)="handleFilter($event, field.modelKey)"-->
<!--          (valueChange)="fieldChanged(field.value.name)"-->
<!--          [required]="isRequired(field.value.formControl)"-->
<!--          [readonly]="field.value.readonly">-->
<!--        </kendo-autocomplete>-->
<!--      </div>-->
<!--    </ng-container>-->

<!--    <ng-container *ngSwitchCase="fieldType.Textarea">-->
<!--      <div class="fink-field-wrapper">-->
<!--        <label [for]="field.value.name">{{field.value.description}}<span *ngIf="isRequired(field.value.formControl)">*</span></label>-->
<!--        <textarea kendoTextArea-->
<!--          [formControlName]="field.value.name"-->
<!--          ([name])="field.value.name"-->
<!--          [id]="field.value.name"-->
<!--          (input)="fieldChanged(field.value.name)"-->
<!--          [required]="isRequired(field.value.formControl)"-->
<!--          [readonly]="field.value.readonly">-->
<!--        </textarea>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-container *ngSwitchCase="fieldType.CheckBox">-->
<!--      <div class="fink-field-wrapper">-->
<!--        <label class="k-form-field">-->
<!--          <input type="checkbox" class="k-checkbox"-->
<!--                 [formControlName]="field.value.name"-->
<!--                 ([name])="field.value.name"-->
<!--                 [id]="field.value.name"-->
<!--                 (change)="fieldChanged(field.value.name)"-->
<!--                 [readonly]="field.value.readonly"/>-->
<!--          <label class="k-checkbox-label" [for]="field.value.name">{{field.value.description}}</label>-->
<!--        </label>-->
<!--      </div>-->
<!--    </ng-container>-->
  </div>
</div>
<div *ngFor="let field of formFields" [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="formComponentType.COMPOSITE">
      <ngx-fink-fieldset [columns]="columns" [formFields]="field.children" [formGroup]="formGroup">
      </ngx-fink-fieldset>
    </ng-container>
</div>
