import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {AuthGuard} from './auth/auth-guard.service';
import {PermissionGuard} from './auth/permission-guard.service';
import {DashboardComponent} from './pages/dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'views',
    loadChildren: () => import('./views/views.module')
      .then(m => m.ViewsModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(x => x.CrmAuthModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./core/crm-core.module').then(m => m.CrmCoreModule),
  },
  { path: '', redirectTo: 'pages/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/dashboard' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
