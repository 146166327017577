import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {getDeepFromObject, NB_AUTH_OPTIONS, NbAuthResult, NbAuthSocialLink, NbLoginComponent} from '@nebular/auth';
import {CrmAuthStrategy} from '../strategy/crm-auth-strategy.class';
import {CrmAuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-crm-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class CrmLoginComponent implements OnInit {

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  rememberMe = false;

  constructor(protected service: CrmAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }

  ngOnInit(): void {
    this.strategy = CrmAuthStrategy.STRATEGY_NAME;
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe(
      (result: NbAuthResult) => this.handleResult(result),
      error => {
        this.submitted = false;
        this.errors.push(error.message);
      });

  }

  private handleResult(result: NbAuthResult) {
    this.submitted = false;

    if (result.isSuccess()) {
      this.messages = result.getMessages();
      if (result.getResponse().passwordExpired) {
        this.redirect('/auth/update-password');
      } else {
        this.redirect(result.getRedirect());
      }
    } else {
      if (result.getResponse().enabled2FA) {
        this.user.enabled2FA = true;
        this.user.sessionId = result.getResponse().sessionId;
      } else {
        this.errors = result.getErrors();
      }
    }
  }

  redirect(redirect) {
    if (redirect) {
      setTimeout(() => {
        return this.router.navigateByUrl(redirect);
      }, this.redirectDelay);
    }
    this.cd.detectChanges();
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
